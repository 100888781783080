.shop {
    .shop-details,
    .shop-social,
    .shop-others {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .1);
    }

    .shop-others {
        .show-less-shops {
            display: none;
        }

        [data-toggle=collapse][aria-expanded=true] {
            .show-more-shops {
                display: none;
            }

            .show-less-shops {
                display: inline;
            }
        }
    }
}