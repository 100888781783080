@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Hairline'), local('Lato-Hairline'), url('../fonts/latohairline.woff2') format('woff2'), url('../fonts/latohairline.woff') format('woff'), url('../fonts/latohairline.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'), url('../fonts/latohairlineitalic.woff2') format('woff2'), url('../fonts/latohairlineitalic.woff') format('woff'), url('../fonts/latohairlineitalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Thin'), local('Lato-Thin'), url('../fonts/latothin.woff2') format('woff2'), url('../fonts/latothin.woff') format('woff'), url('../fonts/latothin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Thin Italic'), local('Lato-ThinItalic'), url('../fonts/latothinitalic.woff2') format('woff2'), url('../fonts/latothinitalic.woff') format('woff'), url('../fonts/latothinitalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Light'), local('Lato-Light'), url('../fonts/latolight.woff2') format('woff2'), url('../fonts/latolight.woff') format('woff'), url('../fonts/latolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Light Italic'), local('Lato-LightItalic'), url('../fonts/latolightitalic.woff2') format('woff2'), url('../fonts/latolightitalic.woff') format('woff'), url('../fonts/latolightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Regular'), local('Lato-Regular'), url('../fonts/latoregular.woff2') format('woff2'), url('../fonts/latoregular.woff') format('woff'), url('../fonts/latoregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Italic'), local('Lato-Italic'), url('../fonts/latoitalic.woff2') format('woff2'), url('../fonts/latoitalic.woff') format('woff'), url('../fonts/latoitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Medium'), local('Lato-Medium'), url('../fonts/latomedium.woff2') format('woff2'), url('../fonts/latomedium.woff') format('woff'), url('../fonts/latomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Medium Italic'), local('Lato-MediumItalic'), url('../fonts/latomediumitalic.woff2') format('woff2'), url('../fonts/latomediumitalic.woff') format('woff'), url('../fonts/latomediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Semibold'), local('Lato-Semibold'), url('../fonts/latosemibold.woff2') format('woff2'), url('../fonts/latosemibold.woff') format('woff'), url('../fonts/latosemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'), url('../fonts/latosemibolditalic.woff2') format('woff2'), url('../fonts/latosemibolditalic.woff') format('woff'), url('../fonts/latosemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Bold'), local('Lato-Bold'), url('../fonts/latobold.woff2') format('woff2'), url('../fonts/latobold.woff') format('woff'), url('../fonts/latobold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url('../fonts/latobolditalic.woff2') format('woff2'), url('../fonts/latobolditalic.woff') format('woff'), url('../fonts/latobolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Heavy'), local('Lato-Heavy'), url('../fonts/latoheavy.woff2') format('woff2'), url('../fonts/latoheavy.woff') format('woff'), url('../fonts/latoheavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Heavy Italic'), local('Lato-HeavyItalic'), url('../fonts/latoheavyitalic.woff2') format('woff2'), url('../fonts/latoheavyitalic.woff') format('woff'), url('../fonts/latoheavyitalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Black'), local('Lato-Black'), url('../fonts/latoblack.woff2') format('woff2'), url('../fonts/latoblack.woff') format('woff'), url('../fonts/latoblack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  font-display: auto;
  src: local('Lato Black Italic'), local('Lato-BlackItalic'), url('../fonts/latoblackitalic.woff2') format('woff2'), url('../fonts/latoblackitalic.woff') format('woff'), url('../fonts/latoblackitalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto-Medium';
  font-display: auto;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('../fonts/Robotomedium.woff2') format('woff2'), url('../fonts/Robotomedium.woff') format('woff'), url('../fonts/Robotomedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('../fonts/roboto-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v19-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-display: auto;
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v19-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('../fonts/roboto-v19-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v19-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v19-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v19-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v19-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('../fonts/roboto-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v19-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v19-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}