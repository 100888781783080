#toast-container.toast-top-center {
  left: 0;
  right: 0;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  top: 20px;
  display: inline-block;
  margin: 0 auto;

  > .toast {
    position: relative;
    box-shadow: none;
    opacity: 1;
    overflow: inherit;
    max-width: 100%;
    width: auto;
    padding: 0.75rem 1.25rem;
    font-size: $font-size-base;

    &:hover {
      box-shadow: none;
    }

    @each $color, $value in $theme-colors {
      &.toast-#{$color} {
        @include alert-variant(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
        background-image: none !important;
      }
    }

    &.toast-error {
      @include alert-variant(theme-color-level('danger', $alert-bg-level), theme-color-level('danger', $alert-border-level), theme-color-level('danger', $alert-color-level));
      background-color: #fee2e1;
      border-color: #fdd6d6;
      background-image: none !important;

      a {
        color: $body-color;
      }
    }

    .toast-close-button {
      opacity: 1;
      position: absolute;
      right: -15px !important;
      top: -15px !important;
      background-color: #2a3142;
      color: #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-shadow: none;
      padding: 0;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;

      &:hover {
        color: #fff;
        background-color: #232b32;
        opacity: 1;
      }
    }
  }
}