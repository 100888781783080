body {
  background-color: #fff;
  font-family: Lato, sans-serif;

  &.header-fixed {
    header.navbar.navbar-light {
      .logo {
        display: inline;
      }

      .logo-icon {
        display: none;
      }
    }

    &.user-signed-in {
      @include media-breakpoint-down(md) {
        header.navbar.navbar-light {
          flex: 0 0 110px;
          height: 110px;

          .logo {
            display: none;
          }

          .logo-icon {
            display: inline;
          }
        }

        .app-body {
          margin-top: 110px;
        }
      }
    }
  }

  .main {
    .container-fluid {
      padding-left: $spacer / 2;
      padding-right: $spacer / 2;

      @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .full-page {
    display: flex;
  }

  &.full-page-wrap {
    section {
      min-height: calc(100vh - #{$navbar-height});
      display: flex;

      &.section-flash {
        min-height: auto;

        .alert {
          width: 100%;
          margin-bottom: 0;

          + .alert {
            margin-top: $spacer;
          }
        }
      }
    }
  }

  section .section-subheading {
    font-size: 1rem;
    font-weight: normal;
    color: $body-color;
    font-family: Lato, sans-serif;
  }

  .carousel-indicators {
    li {
      margin-right: calc-rem(18px);
      margin-left: 0;
      border: none;
    }

    .active {
      background-color: $primary;
      box-shadow: 0 3px 5px 0 rgba(32, 168, 216, 0.3);
      width: calc-rem(40px);
      height: calc-rem(4px);
    }
  }

  .sprite-icon-play {
    margin-left: calc-rem(-35px);
    margin-top: calc-rem(-15px);
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size;

    h1, .h1 {
      font-size: $h1-font-size;
    }
    h3 {
      font-size: $h3-font-size;
    }
  }

  @include media-breakpoint-down(lg) {
    font-size: $font-size-sm;

    h1, .h1 {
      font-size: 26px;
    }

    h3, .h3 {
      font-size: 20px;
    }
    .sprite-icon-play {
      zoom: 0.6;
      margin-left: -40px;
      margin-top: -20px;
    }
  }
}

.noscroll {
  overflow: hidden;
}