.input-group > .twitter-typeahead {
  flex: 1 1 auto;
  width: auto;
}

@media (max-width: 483px) {
  .input-group > .twitter-typeahead {
    width: 50%;
  }
}

@media (max-width: 449px) {
  .input-group > .twitter-typeahead {
    width: 45%;
  }
}

@media (max-width: 415px) {
  .input-group > .twitter-typeahead {
    width: 40%;
  }
}

.twitter-typeahead {
  position: relative;
  width: 100%;


  &.hidden {
    display: none;
  }

  .tt-input, .tt-hint {
    border-radius: 0;
  }

  .tt-loader {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    right: $spacer / 2;
  }

  .tt-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    .tt-dataset {

      .tt-selectable {
        cursor: pointer;

        &:hover {
          background-color: $primary;
          color: $white;
        }
      }
    }

    .tt-sugestion {
      display: block;
      width: 100%;
      padding: 0.25rem 1.5rem;
      clear: both;
      font-weight: 400;
      font-size: 14px !important;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      background-color: transparent;
      border: 0;
    }
  }

  li {
    line-height: 20px;
  }

  a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: none;
      background-color: #ab8ce4;
      outline: 0;
    }
  }

  .tt-query {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  }

  .tt-hint {
    color: #999999;
    width: 100%;
  }

  .tt-dropdown-menu {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-top: 12px;
    padding: 8px 0;
    width: 100%;
  }

  .tt-input {
    width: 100%;
  }

  .tt-input-group {
    width: 100%;
  }

  .tt-suggestion {
    font-size: 18px;
    line-height: 24px;
    padding: 3px 20px;

    &.tt-cursor {
      background-color: #ab8ce4;
      color: #FFFFFF;

      .text-muted {
        color: #EEE !important;
      }
    }

    p {
      margin: 0;
    }
  }
  .tt-suggestion:hover {
    cursor: pointer;
    color: $white;
    background-color: $primary;
  }
  .tt-suggestion.tt-cursor {
    color: $white;
    background-color: $primary;
  }
}