#search-deals-section, #search-stores-section {
  background-color: $gray-800;

  #search-deals-form, #search-stores-form {
    .input-group-prepend {
      max-width: 33%;

      .btn-light {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    #search {
      min-width: 33%;
      padding: .5625rem 1.25rem;
    }
  }
}

#search-section {
  .btn.multiselect {
    display: flex;
    align-items: center;
    justify-content: space-between;

    + .dropdown-menu {
      right: 0;
    }
  }
}