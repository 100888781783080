form {

  .form-group {

    select {
      appearance: none;
      background: url(../img/icons/select-arr.svg) no-repeat #fff calc(100% - 12px) center;
      background-size: 8px 5px;
    }

    textarea {
      min-height: 20rem;

      &.textarea-sm {
        min-height: 10rem;
      }

      &.textarea-xs {
        min-height: 5rem;
      }
    }

    .form-control {
      transition: border-color 0s ease-in-out;
    }

    .input-group.focused {
      border-color: #8ad4ee;
      box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
      border-radius: $border-radius;
    }

    .input-group-shadow {
      @include block-box-small-shadow;

      .input-group-prepend {
        .input-group-text {
          border: 0;
          background-color: #fff;
        }

        + .form-control {
          padding-left: 0;
        }
      }

      &.input-group-textarea {
        textarea {
          padding-top: $input-padding-y * 1.3;
        }
      }

      .form-control {
        box-shadow: none;
        border: none;
      }

      .tooltip {
        left: 0;
        right: 0;

        .tooltip-inner {
          max-width: 100%;
        }
      }
    }
  }

  &.fv-plugins-bootstrap {

    $validation-icon-height: 34px;

    .fv-plugins-icon {
      width: $validation-icon-height;
      height: $validation-icon-height;
      line-height: $validation-icon-height;
      font-size: 0.6rem;

      &.fa-asterisk {
        color: $primary;
      }
    }

    .sr-only + .form-controls .fv-plugins-icon {
      top: ($input-height - $validation-icon-height) / 2;
      right: 0;
    }

    .fv-plugins-icon-container .select2-selection__arrow {
      right: 2rem;
    }
  }

  .multiselect-container {
    border-width: $input-border-width;
    border-color: $input-border-color;

    > li > a {
      color: $body-color;

      > label {
        padding: 5px 0 5px $input-padding-x;
      }
    }
  }
}