.table {
  th, td {
    vertical-align: middle;
  }

  thead {
    th {
      border: 0;
    }
  }

  td {
    .table {
      background-color: transparent;

      tbody tr:first-child td {
        border-top: 0;
      }

      tbody tr:nth-of-type(odd),
      tbody tr:nth-of-type(even) {
        background-color: transparent;
      }
    }
  }

  tr.new.inbox,
  tr.new.unread {
    font-weight: bold;
  }
}