.faq-tabs {
  border: none;

  .nav-link {
    background: transparent;
    border: none;
    color: rgba(51, 62, 72, 0.5);

    &.active {
      background: $white;
      border-top: 2px solid $primary;
      box-shadow: 0px -3px 5px -1px rgba(0, 0, 0, 0.1);
      color: $dark;

      &:focus {
        background: $white;
        border-top: 2px solid $primary;
        box-shadow: 0px -3px 5px -1px rgba(0, 0, 0, 0.1);
        color: $dark;
      }
    }
  }
}

.faq-tab-content {
  @include block-box-small-shadow;
  background: #fff;
  border: 0;
}

.faq-accordion {
  .card {
    .card-header {
      padding: 0;

      h5 a {
        padding: map_get($spacers, 2) map_get($spacers, '2-5');
      }
    }
  }
}