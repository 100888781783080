.popover{
  opacity: 0.92;
}

.favorite {
  cursor: pointer;
}

.listing-img {
  max-width: 50px;
  max-height: 50px;
  display: block;
  margin: auto;
}

.listing {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid #f3f3f3;
  border-radius: .3125rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

  &.listing-item {
    margin-bottom: $spacer;
  }

  &:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  }

  .line {
    width: calc(100% + 1.8rem);
    margin-left: -.9rem;
    margin-bottom: .625rem;
    height: 18px;
    display: block;
    background: url(../img/separator.png) no-repeat;
    background-size: cover;
  }

  .title {
    font-size: $font-size-base;

    a {
      color: $dark;
      font-family: 'Roboto-Medium', sans-serif;
      font-weight: 500;
    }
  }

  .full-price {
    display: inline-block;
    margin-right: 0.3rem;
    font-size: $font-size-sm;
    line-height: $font-size-sm;
  }

  .price {
    font-size: $font-size-base;
    font-weight: 700;
  }

  .percent {
    font-size: $font-size-base;
    text-transform: uppercase;
    border-radius: $border-radius;
    padding: calc-rem(5px) calc-rem(10px);
    white-space: nowrap;
    color: $white;

    @include media-breakpoint-down(lg) {
      padding: calc-rem(5px);
    }
  }

  .fa-bolt {
    display: inline-block;
    margin-right: 1rem;
  }

  .listing-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.2rem;

    .listing-title {
      font-size: 1.2rem;
    }

    > div {
      margin-left: 10px;

      .fa {
        cursor: pointer;
      }

      &.release {
        color: #dadada;
      }

      &.share {
        position: relative;
        color: #dadada;
        max-height: 28px;
        z-index: 10;

        > div {
          position: absolute;
          top: 100%;
          left: -5px;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 5px;
          border-radius: 0.5rem;

          .fab:hover {
            color: darken(#dadada, 15%);
          }
        }
      }
    }
  }

  .preview {
    display: block;
    position: relative;
    margin-bottom: $spacer;
    height: 225px;

    .loader {
      color: $grey;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      flex-direction: column;
      background-color: #fff;
      text-align: center;
      height: 225px;
    }

    .lozad {
      height: 225px;

      &[data-loaded] {
        + .loader {
          display: none;
        }
      }
    }

    .availability {
      color: $red;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      flex-direction: column;
      background-color: rgba(255, 255, 255, 0.9);
      text-align: center;
    }
  }

  .badge {
    font-size: .8rem;
    padding: .6em;
    font-weight: 400;

    + .badge {
      margin-left: 0.2rem;
    }

    &.badge-remaining {
      background-color: #555;
      color: #fff;
    }
  }

  &.ending {
    .badge-remaining {
      background-color: theme-color('danger');
    }
  }
}

.listing-single {

  .social {
    .favorite {
      box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
      width: 1.875rem;
      height: 1.875rem;
      margin: 0 .5rem 0 0;
      border-radius: 50%;
      border: 1px solid #f3f3f3;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s ease-in-out;

      i {
        position: relative;
        width: 30px;
        min-width: 30px;
        height: 30px;

        &:before {
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }

      &:hover {
        transform: translateY(-4px);
      }
    }

    .addthis_inline_share_toolbox {
      a {
        border-radius: 50% !important;
        margin: 0 .5rem 0 0;
      }
    }
  }

  .listing-title {
    font-size: 1.6rem;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }

  .old-price {
    font-size: $font-size-base;
    color: $body-color;

    del {
      vertical-align: sub;
    }
  }

  .percent {
    text-transform: uppercase;
    border-radius: $border-radius;
    border: none;
    padding: calc-rem(5px);
    white-space: nowrap;
  }

  .product-gallery {
    .slider-nav {
      height: calc-rem(380px);
      max-width: calc-rem(66px);
      visibility: hidden;

      .slick-slide {
        height: calc-rem(56px);
        width: calc-rem(56px) !important;
        display: flex;
        margin-top: calc-rem(10px);
        margin-right: calc-rem(10px);
        border-radius: 3px;
        overflow: hidden;
        border: 2px solid transparent;
        align-items: center;
        justify-content: center;

        img {
          max-width: calc-rem(56px);
          max-height: calc-rem(56px);
          width: auto;
          height: auto;
          margin: auto
        }

        &.slick-current {
          border: 2px solid $primary;
        }
      }

      .slick-arrow {
        text-align: center;
        color: #dadada;

        :hover {
          color: $primary;
        }

        .fas {
          margin-left: -6px;
        }
      }
    }

    .slider-main-img {
      height: calc-rem(380px);
      width: calc-rem(380px);
      visibility: hidden;

      .slick-slide {
        height: calc-rem(380px);
        display: flex;

        > div {
          display: flex;
          margin: 0 auto;
        }

        img {
          max-width: 100%;
          width: auto;
          height: auto;
          margin: auto
        }
      }

      .slick-dots li {
        margin: 0;
      }
    }

    @include media-breakpoint-down(md) {
      .slider-main-img {
        height: calc-rem(320px);
        width: calc-rem(320px);

        .slick-slide {
          height: calc-rem(320px);
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .slider-main-img {
        height: calc-rem(255px);
        width: calc-rem(255px);
        .slick-slide {
          height: calc-rem(255px);
        }
      }
    }
  }

  .listing-coupon-code {
    text-align: center;
    font-size: $font-size-lg * 1.2;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      width: 60px;
      height: 60px;
      background-color: $coupon;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    div {
      height: 60px;
      border: 3px dashed $coupon;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-left: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
  }
}

.modal-listing {

  .modal-header,
  .modal-footer {
    border-width: 0;
  }

  .modal-header {
    .close {
      display: block;
      position: absolute;
      top: 1.25rem;
      right: 1.875rem;
      font-size: 1.875rem;
      color: #848484;
      opacity: 0.8;
      z-index: 3;
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-dialog {
      max-width: $modal-sm;
    }
  }

  @include media-breakpoint-up(md) {
    .modal-dialog {
      max-width: $modal-md;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-dialog {
      max-width: $modal-lg;
    }
  }

  @include media-breakpoint-up(xl) {
    .modal-dialog {
      max-width: $modal-xxl;
    }
  }

  .modal-description {
    &:focus {
      box-shadow: none;
    }

    &.collapsed {
      .hide-description {
        display: none;
      }

      .show-description {
        display: block;
      }
    }

    .hide-description {
      display: block;
    }

    .show-description {
      display: none;
    }
  }
}

.listing-slider {
  position: relative;

  .loading {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.listing-shop {
  min-height: 150px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,.1);

  .listing-discount {
    font-size: 1.4rem;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      border-right: 1px dashed rgba(0,0,0,.1);
    }
  }
}