$enable-validation-icons: false;
$base-font-size: 16px;
$font-size-base: 1rem;
$link-hover-decoration: none;

@function calc-rem ($target, $context: $base-font-size) {
  @return ($target / $context) * 1rem;
}

@function createGradient($to, $from, $direction: to bottom) {
  @return linear-gradient($direction, $to, $from);
}

$white: #ffffff !default;
$ghostwhite: #f9fbfe !default;
$blue: #20a8d8 !default;
$light-blue: #0db3e3 !default;
$cyan: #17a2b8 !default;
$purple: #6f42c1 !default;
$grey: #666666 !default;
$darkslategrey: #333e48 !default;
$orange: #ffbb33 !default;
$red: #ff3547 !default;
$light-grey: #eaf0f5 !default;
$medium-grey: #ddd !default;
$whitesmoke: #f3f3f3 !default;
$black: #000 !default;
$green: #4dbd74 !default;
$light-red: #f86c6b !default;
$facebook: #475893 !default;
$body-color: $grey !default;
$coupon: #ea4c89 !default;
$rebate: $blue !default;
$deal: $purple !default;
$chrome: #1a73e8 !default;

$colors: (
        'white': $white,
        'blue': $blue,
        'light-blue': $light-blue,
        'dark': $darkslategrey,
        'black': $black,
        'grey': $grey,
        'light-grey': $light-grey,
        'medium-grey': $medium-grey,
        'ghostwhite': $ghostwhite,
        'red': $red,
        'green': $green,
        'orange': $orange
);

$primary: $blue !default;
$primary-light: $light-blue !default;
$secondary: $white !default;
$success: $green !default;
$info: $light-blue !default;
$warning: $orange !default;
$danger: $light-red !default;
$light: $light-grey !default;
$dark: $darkslategrey !default;
$grey: $grey !default;
$red: $red !default;
$green: $green !default;
$orange: $orange !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
                (
                        "primary": $primary,
                        "primary-light": $primary-light,
                        "secondary": $secondary,
                        "success": $success,
                        "info": $info,
                        "warning": $warning,
                        "danger": $danger,
                        "light": $light,
                        "medium-grey": $medium-grey,
                        "dark": $dark,
                        "white": $white,
                        "grey": $grey,
                        "ghostwhite": $ghostwhite,
                        "red": $red,
                        "green": $green,
                        "orange": $orange,
                        "facebook": $facebook,
                        "chrome": $chrome,
                        "coupon": $coupon,
                        "rebate": $rebate,
                        "deal": $deal,
                ),
                $theme-colors
);

$card-spacer-y: calc-rem(20px) !default;
$card-spacer-x: calc-rem(25px) !default;
$card-border-width: 1px !default;
$card-border-color: $whitesmoke !default;
$card-border-radius: calc-rem(5px) !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1570px,
        uxxl: 1770px,
) !default;

//@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
//@include _assert-starts-at-zero($grid-breakpoints);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1210px,
        uxxl: 1600px,
) !default;

//@include _assert-ascending($container-max-widths, "$container-max-widths");

$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;

$font-size-lg: calc-rem(18px);
$font-size: calc-rem(16px);
$font-size-sm: calc-rem(14px);
$font-size-small: calc-rem(12px);

$h1-font-size: calc-rem(36px) !default;
$h2-font-size: calc-rem(32px) !default;
$h3-font-size: calc-rem(28px) !default;
$h4-font-size: calc-rem(22px) !default;
$h5-font-size: calc-rem(20px) !default;
$h6-font-size: calc-rem(16px) !default;

$h1-font-size-mobile: calc-rem(24px) !default;

$nav-link-size: 0.875rem;
$navbar-nav-link-padding-x: calc-rem(20px) !default;
$border-width: 1px !default;
$box-border-width: 10px !default;
$navbar-padding-y: calc-rem(26px) !default;

//Buttons + Forms

$form-group-margin-bottom: calc-rem(26px);
$input-border-color: $whitesmoke;

//Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: calc-rem(9px) !default;
$input-btn-padding-x: calc-rem(20px) !default;

$input-btn-focus-width: .2rem !default;

$input-btn-padding-y-sm: calc-rem(9px) !default;
$input-btn-padding-x-sm: calc-rem(35px) !default;

$input-btn-padding-y-lg: $input-btn-padding-y !default;
$input-btn-padding-x-lg: 2.875rem !default;
$input-color: $grey !default;
$input-font-size: calc-rem(15px) !default;

//$input-group-addon-border-color: $whitesmoke !default;

$btn-font-weight: $font-weight-medium !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;

$border-radius: calc-rem(3px) !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1.25rem !default;

$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        '0-5': calc-rem(5px),
                        '0-8': calc-rem(8px),
                        1: calc-rem(10px),
                        '1-5': calc-rem(15px),
                        2: calc-rem(20px),
                        '2-5': calc-rem(25px),
                        3: calc-rem(30px),
                        '3-5': calc-rem(35px),
                        4: calc-rem(40px),
                        '4-5': calc-rem(45px),
                        5: calc-rem(50px),
                        '5-5': calc-rem(55px),
                        6: calc-rem(60px),
                        7: calc-rem(70px),
                        '7-5': calc-rem(75px),
                        8: calc-rem(80px),
                        9: calc-rem(90px),
                        '9-5': calc-rem(95px),
                        10: calc-rem(100px),
                        '10-5': calc-rem(105px),
                        11: calc-rem(110px),
                        12: calc-rem(120px),
                        '12-5': calc-rem(125px),
                        13: calc-rem(135px),
                        14: calc-rem(140px),
                        15: calc-rem(150px),
                        '16-5': calc-rem(165px),
                        20: calc-rem(200px),
                        25: calc-rem(250px),
                ),
                $spacers
);

$line-height-base: 1.56 !default;
$line-height-lg: 1.42 !default;
$line-height-sm: 1.3 !default;

// Navbar

$navbar-padding-y: 1.125rem !default;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$navbar-light-color: $dark !default;
$navbar-light-hover-color: rgba($primary, 0.7) !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($grey, .3) !default;
$navbar-light-toggler-border-color: rgba($grey, .1) !default;

$yiq-contrasted-threshold: 195 !default;

$hr-border-color: $whitesmoke !default;
$hr-border-width: 1px !default;
$hr-margin-y: 0 !default;

// Breadcrumbs

$breadcrumb-padding-y: map-get($spacers, 1) !default;
$breadcrumb-padding-x: map-get($spacers, 3) !default;
$breadcrumb-item-padding: .5rem !default;
$breadcrumb-margin-bottom: 0 !default;
$breadcrumb-bg: $white !default;
$breadcrumb-active-color: $grey !default;
$breadcrumb-divider: quote("/") !default;
$breadcrumb-divider-color: $primary !default;
$breadcrumb-border-radius: 0 !default;

$modal-xxl: 1135px !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 16px !default;

$input-height: 44px !default;

$input-height-mobile: 44px !default;

$s2bs-input-height-inner-sm: 12px;
$s2bs-input-height-inner-lg: 18px;

$navbar-height: 60px;