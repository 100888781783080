.sign-in-page {
  @include media-breakpoint-down(sm) {
    header.navbar.navbar-light .container-fluid .navbar-brand {
      position: static;
    }
    .acsb-trigger {
      top: 22px !important;
      right: 20px !important;
    }
  }
}