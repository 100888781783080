header {

  &.navbar.navbar-light {
    background-color: $white;
    border-bottom: 1px solid $whitesmoke;

    .container-fluid {
      padding-left: $spacer / 2;
      padding-right: $spacer / 2;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-up(md) {
        padding-left: 30px;
        padding-right: 30px;
      }

      @include media-breakpoint-up(xl) {
        &.container-fluid-event {
          width: calc(50% + 30px);
          margin-left: 0;
        }
      }

      .navbar-brand {
        width: auto;
        height: inherit;
        justify-content: flex-start;
        position: static;
        margin-left: 0;

        @include media-breakpoint-down(md) {
          margin-left: $spacer / 2;
        }

        .logo {
          width: 120px;
        }

        .logo-icon {
          width: 30px;
        }
      }

      .navbar-logo {
        display: flex;
        align-items: center;

        .container-sidebar-toggler .navbar-toggler {
          margin-right: $spacer / 2;
          padding: 0.25rem 0.4rem;
          min-width: auto;
        }
      }

      .container-navbar-main-menu {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include media-breakpoint-up(lg) {
          width: 450px;
          max-width: 450px;
          margin: 0 $spacer;

          .input-group > .input-group-prepend {
            max-width: 150px;
          }
        }

        @include media-breakpoint-up(xl) {
          width: 650px;
          max-width: 650px;
          margin: 0 auto;

          .input-group > .input-group-prepend {
            max-width: none;
          }
        }

        @include media-breakpoint-down(md) {
          order: 3;
          width: 100%;
        }

        .search-deals-form {

          .input-group {
            > .input-group-prepend {
              .multiselect {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                padding-left: .75rem;
                padding-right: .75rem;
              }

              + .twitter-typeahead {
                .tt-input, .tt-hint {
                  border-bottom-left-radius: 0;
                  border-top-left-radius: 0;
                }
              }
            }

            > .input-group-append {
              position: relative;

              .btn[type=submit] {
                padding-left: .75rem;
                padding-right: .75rem;
              }

              .btn-link {
                position: absolute;
                left: -52px;
                top: 0;
              }
            }
          }
        }

        .navbar-main-menu {
          display: flex;
          font-size: small;
          margin-top: $spacer / 4;

          .nav-item:first-child {
            padding-left: 0;
          }
        }
      }

      .nav-user {
        .announcekit-widget-badge {
          position: absolute !important;
          top: 50%;
          left: 50%;
          margin-top: -16px;
          margin-left: 0;
        }
      }

      .nav-item {
        min-width: auto;
      }

      @include media-breakpoint-down(md) {
        &.navbar-expand-lg > .container {
          padding: 0 8px;
        }
      }

      @include media-breakpoint-up(lg) {
        &.navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 1rem;
          padding-left: 1rem;
        }
      }

      @include media-breakpoint-up(xl) {
        &.navbar-expand-lg .navbar-nav .nav-link {
          padding-right: 1.25rem;
          padding-left: 1.25rem;
        }
      }
    }
  }
}