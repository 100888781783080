// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-amazon-name: 'sprite-icon-amazon';
$icon-amazon-x: 282px;
$icon-amazon-y: 44px;
$icon-amazon-offset-x: -282px;
$icon-amazon-offset-y: -44px;
$icon-amazon-width: 149px;
$icon-amazon-height: 44px;
$icon-amazon-total-width: 431px;
$icon-amazon-total-height: 348px;
$icon-amazon-image: '../img/sprites/spritesheet.png';
$icon-amazon: (282px, 44px, -282px, -44px, 149px, 44px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-amazon', );
$icon-arrow-r-name: 'sprite-icon-arrow-r';
$icon-arrow-r-x: 374px;
$icon-arrow-r-y: 325px;
$icon-arrow-r-offset-x: -374px;
$icon-arrow-r-offset-y: -325px;
$icon-arrow-r-width: 14px;
$icon-arrow-r-height: 11px;
$icon-arrow-r-total-width: 431px;
$icon-arrow-r-total-height: 348px;
$icon-arrow-r-image: '../img/sprites/spritesheet.png';
$icon-arrow-r: (374px, 325px, -374px, -325px, 14px, 11px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-arrow-r', );
$icon-attach-name: 'sprite-icon-attach';
$icon-attach-x: 242px;
$icon-attach-y: 217px;
$icon-attach-offset-x: -242px;
$icon-attach-offset-y: -217px;
$icon-attach-width: 17px;
$icon-attach-height: 17px;
$icon-attach-total-width: 431px;
$icon-attach-total-height: 348px;
$icon-attach-image: '../img/sprites/spritesheet.png';
$icon-attach: (242px, 217px, -242px, -217px, 17px, 17px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-attach', );
$icon-audience-name: 'sprite-icon-audience';
$icon-audience-x: 198px;
$icon-audience-y: 257px;
$icon-audience-offset-x: -198px;
$icon-audience-offset-y: -257px;
$icon-audience-width: 16px;
$icon-audience-height: 16px;
$icon-audience-total-width: 431px;
$icon-audience-total-height: 348px;
$icon-audience-image: '../img/sprites/spritesheet.png';
$icon-audience: (198px, 257px, -198px, -257px, 16px, 16px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-audience', );
$icon-clock-blue-name: 'sprite-icon-clock-blue';
$icon-clock-blue-x: 266px;
$icon-clock-blue-y: 202px;
$icon-clock-blue-offset-x: -266px;
$icon-clock-blue-offset-y: -202px;
$icon-clock-blue-width: 15px;
$icon-clock-blue-height: 15px;
$icon-clock-blue-total-width: 431px;
$icon-clock-blue-total-height: 348px;
$icon-clock-blue-image: '../img/sprites/spritesheet.png';
$icon-clock-blue: (266px, 202px, -266px, -202px, 15px, 15px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-clock-blue', );
$icon-clock-name: 'sprite-icon-clock';
$icon-clock-x: 394px;
$icon-clock-y: 303px;
$icon-clock-offset-x: -394px;
$icon-clock-offset-y: -303px;
$icon-clock-width: 19px;
$icon-clock-height: 19px;
$icon-clock-total-width: 431px;
$icon-clock-total-height: 348px;
$icon-clock-image: '../img/sprites/spritesheet.png';
$icon-clock: (394px, 303px, -394px, -303px, 19px, 19px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-clock', );
$icon-delivery-name: 'sprite-icon-delivery';
$icon-delivery-x: 402px;
$icon-delivery-y: 281px;
$icon-delivery-offset-x: -402px;
$icon-delivery-offset-y: -281px;
$icon-delivery-width: 28px;
$icon-delivery-height: 19px;
$icon-delivery-total-width: 431px;
$icon-delivery-total-height: 348px;
$icon-delivery-image: '../img/sprites/spritesheet.png';
$icon-delivery: (402px, 281px, -402px, -281px, 28px, 19px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-delivery', );
$icon-ebay-name: 'sprite-icon-ebay';
$icon-ebay-x: 282px;
$icon-ebay-y: 0px;
$icon-ebay-offset-x: -282px;
$icon-ebay-offset-y: 0px;
$icon-ebay-width: 149px;
$icon-ebay-height: 44px;
$icon-ebay-total-width: 431px;
$icon-ebay-total-height: 348px;
$icon-ebay-image: '../img/sprites/spritesheet.png';
$icon-ebay: (282px, 0px, -282px, 0px, 149px, 44px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-ebay', );
$icon-envelope-name: 'sprite-icon-envelope';
$icon-envelope-x: 182px;
$icon-envelope-y: 257px;
$icon-envelope-offset-x: -182px;
$icon-envelope-offset-y: -257px;
$icon-envelope-width: 16px;
$icon-envelope-height: 16px;
$icon-envelope-total-width: 431px;
$icon-envelope-total-height: 348px;
$icon-envelope-image: '../img/sprites/spritesheet.png';
$icon-envelope: (182px, 257px, -182px, -257px, 16px, 16px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-envelope', );
$icon-facebook-logo-name: 'sprite-icon-facebook-logo';
$icon-facebook-logo-x: 166px;
$icon-facebook-logo-y: 291px;
$icon-facebook-logo-offset-x: -166px;
$icon-facebook-logo-offset-y: -291px;
$icon-facebook-logo-width: 11px;
$icon-facebook-logo-height: 19px;
$icon-facebook-logo-total-width: 431px;
$icon-facebook-logo-total-height: 348px;
$icon-facebook-logo-image: '../img/sprites/spritesheet.png';
$icon-facebook-logo: (166px, 291px, -166px, -291px, 11px, 19px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-facebook-logo', );
$icon-facebook-round-name: 'sprite-icon-facebook-round';
$icon-facebook-round-x: 399px;
$icon-facebook-round-y: 250px;
$icon-facebook-round-offset-x: -399px;
$icon-facebook-round-offset-y: -250px;
$icon-facebook-round-width: 30px;
$icon-facebook-round-height: 30px;
$icon-facebook-round-total-width: 431px;
$icon-facebook-round-total-height: 348px;
$icon-facebook-round-image: '../img/sprites/spritesheet.png';
$icon-facebook-round: (399px, 250px, -399px, -250px, 30px, 30px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-facebook-round', );
$icon-google-plus-name: 'sprite-icon-google-plus';
$icon-google-plus-x: 242px;
$icon-google-plus-y: 202px;
$icon-google-plus-offset-x: -242px;
$icon-google-plus-offset-y: -202px;
$icon-google-plus-width: 24px;
$icon-google-plus-height: 15px;
$icon-google-plus-total-width: 431px;
$icon-google-plus-total-height: 348px;
$icon-google-plus-image: '../img/sprites/spritesheet.png';
$icon-google-plus: (242px, 202px, -242px, -202px, 24px, 15px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-google-plus', );
$icon-handshake-name: 'sprite-icon-handshake';
$icon-handshake-x: 282px;
$icon-handshake-y: 281px;
$icon-handshake-offset-x: -282px;
$icon-handshake-offset-y: -281px;
$icon-handshake-width: 92px;
$icon-handshake-height: 55px;
$icon-handshake-total-width: 431px;
$icon-handshake-total-height: 348px;
$icon-handshake-image: '../img/sprites/spritesheet.png';
$icon-handshake: (282px, 281px, -282px, -281px, 92px, 55px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-handshake', );
$icon-heart-name: 'sprite-icon-heart';
$icon-heart-x: 259px;
$icon-heart-y: 217px;
$icon-heart-offset-x: -259px;
$icon-heart-offset-y: -217px;
$icon-heart-width: 18px;
$icon-heart-height: 16px;
$icon-heart-total-width: 431px;
$icon-heart-total-height: 348px;
$icon-heart-image: '../img/sprites/spritesheet.png';
$icon-heart: (259px, 217px, -259px, -217px, 18px, 16px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-heart', );
$icon-etsy-name: 'sprite-icon-etsy';
$icon-etsy-x: 282px;
$icon-etsy-y: 176px;
$icon-etsy-offset-x: -282px;
$icon-etsy-offset-y: -176px;
$icon-etsy-width: 149px;
$icon-etsy-height: 44px;
$icon-etsy-total-width: 431px;
$icon-etsy-total-height: 348px;
$icon-etsy-image: '../img/sprites/spritesheet.png';
$icon-etsy: (282px, 176px, -282px, -176px, 149px, 44px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-etsy', );
$icon-key-name: 'sprite-icon-key';
$icon-key-x: 282px;
$icon-key-y: 220px;
$icon-key-offset-x: -282px;
$icon-key-offset-y: -220px;
$icon-key-width: 87px;
$icon-key-height: 61px;
$icon-key-total-width: 431px;
$icon-key-total-height: 348px;
$icon-key-image: '../img/sprites/spritesheet.png';
$icon-key: (282px, 220px, -282px, -220px, 87px, 61px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-key', );
$icon-li-check-name: 'sprite-icon-li-check';
$icon-li-check-x: 282px;
$icon-li-check-y: 336px;
$icon-li-check-offset-x: -282px;
$icon-li-check-offset-y: -336px;
$icon-li-check-width: 14px;
$icon-li-check-height: 12px;
$icon-li-check-total-width: 431px;
$icon-li-check-total-height: 348px;
$icon-li-check-image: '../img/sprites/spritesheet.png';
$icon-li-check: (282px, 336px, -282px, -336px, 14px, 12px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-li-check', );
$icon-linkedin-name: 'sprite-icon-linkedin';
$icon-linkedin-x: 260px;
$icon-linkedin-y: 182px;
$icon-linkedin-offset-x: -260px;
$icon-linkedin-offset-y: -182px;
$icon-linkedin-width: 17px;
$icon-linkedin-height: 17px;
$icon-linkedin-total-width: 431px;
$icon-linkedin-total-height: 348px;
$icon-linkedin-image: '../img/sprites/spritesheet.png';
$icon-linkedin: (260px, 182px, -260px, -182px, 17px, 17px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-linkedin', );
$icon-location-name: 'sprite-icon-location';
$icon-location-x: 166px;
$icon-location-y: 273px;
$icon-location-offset-x: -166px;
$icon-location-offset-y: -273px;
$icon-location-width: 14px;
$icon-location-height: 18px;
$icon-location-total-width: 431px;
$icon-location-total-height: 348px;
$icon-location-image: '../img/sprites/spritesheet.png';
$icon-location: (166px, 273px, -166px, -273px, 14px, 18px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-location', );
$icon-message-name: 'sprite-icon-message';
$icon-message-x: 413px;
$icon-message-y: 303px;
$icon-message-offset-x: -413px;
$icon-message-offset-y: -303px;
$icon-message-width: 17px;
$icon-message-height: 17px;
$icon-message-total-width: 431px;
$icon-message-total-height: 348px;
$icon-message-image: '../img/sprites/spritesheet.png';
$icon-message: (413px, 303px, -413px, -303px, 17px, 17px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-message', );
$icon-notification-blue-name: 'sprite-icon-notification-blue';
$icon-notification-blue-x: 374px;
$icon-notification-blue-y: 281px;
$icon-notification-blue-offset-x: -374px;
$icon-notification-blue-offset-y: -281px;
$icon-notification-blue-width: 28px;
$icon-notification-blue-height: 22px;
$icon-notification-blue-total-width: 431px;
$icon-notification-blue-total-height: 348px;
$icon-notification-blue-image: '../img/sprites/spritesheet.png';
$icon-notification-blue: (374px, 281px, -374px, -281px, 28px, 22px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-notification-blue', );
$icon-pass-name: 'sprite-icon-pass';
$icon-pass-x: 225px;
$icon-pass-y: 257px;
$icon-pass-offset-x: -225px;
$icon-pass-offset-y: -257px;
$icon-pass-width: 10px;
$icon-pass-height: 16px;
$icon-pass-total-width: 431px;
$icon-pass-total-height: 348px;
$icon-pass-image: '../img/sprites/spritesheet.png';
$icon-pass: (225px, 257px, -225px, -257px, 10px, 16px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-pass', );
$icon-phone-name: 'sprite-icon-phone';
$icon-phone-x: 166px;
$icon-phone-y: 257px;
$icon-phone-offset-x: -166px;
$icon-phone-offset-y: -257px;
$icon-phone-width: 16px;
$icon-phone-height: 16px;
$icon-phone-total-width: 431px;
$icon-phone-total-height: 348px;
$icon-phone-image: '../img/sprites/spritesheet.png';
$icon-phone: (166px, 257px, -166px, -257px, 16px, 16px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-phone', );
$icon-piggy-bank-name: 'sprite-icon-piggy-bank';
$icon-piggy-bank-x: 374px;
$icon-piggy-bank-y: 303px;
$icon-piggy-bank-offset-x: -374px;
$icon-piggy-bank-offset-y: -303px;
$icon-piggy-bank-width: 20px;
$icon-piggy-bank-height: 22px;
$icon-piggy-bank-total-width: 431px;
$icon-piggy-bank-total-height: 348px;
$icon-piggy-bank-image: '../img/sprites/spritesheet.png';
$icon-piggy-bank: (374px, 303px, -374px, -303px, 20px, 22px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-piggy-bank', );
$icon-pinterest-round-name: 'sprite-icon-pinterest-round';
$icon-pinterest-round-x: 369px;
$icon-pinterest-round-y: 220px;
$icon-pinterest-round-offset-x: -369px;
$icon-pinterest-round-offset-y: -220px;
$icon-pinterest-round-width: 30px;
$icon-pinterest-round-height: 30px;
$icon-pinterest-round-total-width: 431px;
$icon-pinterest-round-total-height: 348px;
$icon-pinterest-round-image: '../img/sprites/spritesheet.png';
$icon-pinterest-round: (369px, 220px, -369px, -220px, 30px, 30px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-pinterest-round', );
$icon-play-name: 'sprite-icon-play';
$icon-play-x: 0px;
$icon-play-y: 0px;
$icon-play-offset-x: 0px;
$icon-play-offset-y: 0px;
$icon-play-width: 166px;
$icon-play-height: 166px;
$icon-play-total-width: 431px;
$icon-play-total-height: 348px;
$icon-play-image: '../img/sprites/spritesheet.png';
$icon-play: (0px, 0px, 0px, 0px, 166px, 166px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-play', );
$icon-quote-purple-name: 'sprite-icon-quote-purple';
$icon-quote-purple-x: 388px;
$icon-quote-purple-y: 325px;
$icon-quote-purple-offset-x: -388px;
$icon-quote-purple-offset-y: -325px;
$icon-quote-purple-width: 10px;
$icon-quote-purple-height: 9px;
$icon-quote-purple-total-width: 431px;
$icon-quote-purple-total-height: 348px;
$icon-quote-purple-image: '../img/sprites/spritesheet.png';
$icon-quote-purple: (388px, 325px, -388px, -325px, 10px, 9px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-quote-purple', );
$icon-scissor-name: 'sprite-icon-scissor';
$icon-scissor-x: 185px;
$icon-scissor-y: 242px;
$icon-scissor-offset-x: -185px;
$icon-scissor-offset-y: -242px;
$icon-scissor-width: 18px;
$icon-scissor-height: 14px;
$icon-scissor-total-width: 431px;
$icon-scissor-total-height: 348px;
$icon-scissor-image: '../img/sprites/spritesheet.png';
$icon-scissor: (185px, 242px, -185px, -242px, 18px, 14px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-scissor', );
$icon-share-name: 'sprite-icon-share';
$icon-share-x: 242px;
$icon-share-y: 182px;
$icon-share-offset-x: -242px;
$icon-share-offset-y: -182px;
$icon-share-width: 18px;
$icon-share-height: 20px;
$icon-share-total-width: 431px;
$icon-share-total-height: 348px;
$icon-share-image: '../img/sprites/spritesheet.png';
$icon-share: (242px, 182px, -242px, -182px, 18px, 20px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-share', );
$icon-shopify-name: 'sprite-icon-shopify';
$icon-shopify-x: 282px;
$icon-shopify-y: 132px;
$icon-shopify-offset-x: -282px;
$icon-shopify-offset-y: -132px;
$icon-shopify-width: 149px;
$icon-shopify-height: 44px;
$icon-shopify-total-width: 431px;
$icon-shopify-total-height: 348px;
$icon-shopify-image: '../img/sprites/spritesheet.png';
$icon-shopify: (282px, 132px, -282px, -132px, 149px, 44px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-shopify', );
$icon-sign-up-buyer-name: 'sprite-icon-sign-up-buyer';
$icon-sign-up-buyer-x: 166px;
$icon-sign-up-buyer-y: 0px;
$icon-sign-up-buyer-offset-x: -166px;
$icon-sign-up-buyer-offset-y: 0px;
$icon-sign-up-buyer-width: 116px;
$icon-sign-up-buyer-height: 182px;
$icon-sign-up-buyer-total-width: 431px;
$icon-sign-up-buyer-total-height: 348px;
$icon-sign-up-buyer-image: '../img/sprites/spritesheet.png';
$icon-sign-up-buyer: (166px, 0px, -166px, 0px, 116px, 182px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-sign-up-buyer', );
$icon-sign-up-seller-name: 'sprite-icon-sign-up-seller';
$icon-sign-up-seller-x: 0px;
$icon-sign-up-seller-y: 166px;
$icon-sign-up-seller-offset-x: 0px;
$icon-sign-up-seller-offset-y: -166px;
$icon-sign-up-seller-width: 116px;
$icon-sign-up-seller-height: 182px;
$icon-sign-up-seller-total-width: 431px;
$icon-sign-up-seller-total-height: 348px;
$icon-sign-up-seller-image: '../img/sprites/spritesheet.png';
$icon-sign-up-seller: (0px, 166px, 0px, -166px, 116px, 182px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-sign-up-seller', );
$icon-tw-round-name: 'sprite-icon-tw-round';
$icon-tw-round-x: 399px;
$icon-tw-round-y: 220px;
$icon-tw-round-offset-x: -399px;
$icon-tw-round-offset-y: -220px;
$icon-tw-round-width: 30px;
$icon-tw-round-height: 30px;
$icon-tw-round-total-width: 431px;
$icon-tw-round-total-height: 348px;
$icon-tw-round-image: '../img/sprites/spritesheet.png';
$icon-tw-round: (399px, 220px, -399px, -220px, 30px, 30px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-tw-round', );
$icon-twitter-logo-name: 'sprite-icon-twitter-logo';
$icon-twitter-logo-x: 166px;
$icon-twitter-logo-y: 242px;
$icon-twitter-logo-offset-x: -166px;
$icon-twitter-logo-offset-y: -242px;
$icon-twitter-logo-width: 19px;
$icon-twitter-logo-height: 15px;
$icon-twitter-logo-total-width: 431px;
$icon-twitter-logo-total-height: 348px;
$icon-twitter-logo-image: '../img/sprites/spritesheet.png';
$icon-twitter-logo: (166px, 242px, -166px, -242px, 19px, 15px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-twitter-logo', );
$icon-user-name: 'sprite-icon-user';
$icon-user-x: 214px;
$icon-user-y: 257px;
$icon-user-offset-x: -214px;
$icon-user-offset-y: -257px;
$icon-user-width: 11px;
$icon-user-height: 16px;
$icon-user-total-width: 431px;
$icon-user-total-height: 348px;
$icon-user-image: '../img/sprites/spritesheet.png';
$icon-user: (214px, 257px, -214px, -257px, 11px, 16px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-user', );
$icon-voucher-name: 'sprite-icon-voucher';
$icon-voucher-x: 166px;
$icon-voucher-y: 182px;
$icon-voucher-offset-x: -166px;
$icon-voucher-offset-y: -182px;
$icon-voucher-width: 76px;
$icon-voucher-height: 60px;
$icon-voucher-total-width: 431px;
$icon-voucher-total-height: 348px;
$icon-voucher-image: '../img/sprites/spritesheet.png';
$icon-voucher: (166px, 182px, -166px, -182px, 76px, 60px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-voucher', );
$icon-walmart-name: 'sprite-icon-walmart';
$icon-walmart-x: 282px;
$icon-walmart-y: 88px;
$icon-walmart-offset-x: -282px;
$icon-walmart-offset-y: -88px;
$icon-walmart-width: 149px;
$icon-walmart-height: 44px;
$icon-walmart-total-width: 431px;
$icon-walmart-total-height: 348px;
$icon-walmart-image: '../img/sprites/spritesheet.png';
$icon-walmart: (282px, 88px, -282px, -88px, 149px, 44px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-walmart', );
$icon-whatsapp-round-name: 'sprite-icon-whatsapp-round';
$icon-whatsapp-round-x: 369px;
$icon-whatsapp-round-y: 250px;
$icon-whatsapp-round-offset-x: -369px;
$icon-whatsapp-round-offset-y: -250px;
$icon-whatsapp-round-width: 30px;
$icon-whatsapp-round-height: 30px;
$icon-whatsapp-round-total-width: 431px;
$icon-whatsapp-round-total-height: 348px;
$icon-whatsapp-round-image: '../img/sprites/spritesheet.png';
$icon-whatsapp-round: (369px, 250px, -369px, -250px, 30px, 30px, 431px, 348px, '../img/sprites/spritesheet.png', 'sprite-icon-whatsapp-round', );
$icon-amazon-2x-name: 'sprite-icon-amazon@2x';
$icon-amazon-2x-x: 564px;
$icon-amazon-2x-y: 88px;
$icon-amazon-2x-offset-x: -564px;
$icon-amazon-2x-offset-y: -88px;
$icon-amazon-2x-width: 298px;
$icon-amazon-2x-height: 88px;
$icon-amazon-2x-total-width: 862px;
$icon-amazon-2x-total-height: 696px;
$icon-amazon-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-amazon-2x: (564px, 88px, -564px, -88px, 298px, 88px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-amazon@2x', );
$icon-arrow-r-2x-name: 'sprite-icon-arrow-r@2x';
$icon-arrow-r-2x-x: 748px;
$icon-arrow-r-2x-y: 650px;
$icon-arrow-r-2x-offset-x: -748px;
$icon-arrow-r-2x-offset-y: -650px;
$icon-arrow-r-2x-width: 28px;
$icon-arrow-r-2x-height: 22px;
$icon-arrow-r-2x-total-width: 862px;
$icon-arrow-r-2x-total-height: 696px;
$icon-arrow-r-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-arrow-r-2x: (748px, 650px, -748px, -650px, 28px, 22px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-arrow-r@2x', );
$icon-attach-2x-name: 'sprite-icon-attach@2x';
$icon-attach-2x-x: 484px;
$icon-attach-2x-y: 434px;
$icon-attach-2x-offset-x: -484px;
$icon-attach-2x-offset-y: -434px;
$icon-attach-2x-width: 34px;
$icon-attach-2x-height: 34px;
$icon-attach-2x-total-width: 862px;
$icon-attach-2x-total-height: 696px;
$icon-attach-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-attach-2x: (484px, 434px, -484px, -434px, 34px, 34px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-attach@2x', );
$icon-audience-2x-name: 'sprite-icon-audience@2x';
$icon-audience-2x-x: 396px;
$icon-audience-2x-y: 514px;
$icon-audience-2x-offset-x: -396px;
$icon-audience-2x-offset-y: -514px;
$icon-audience-2x-width: 32px;
$icon-audience-2x-height: 32px;
$icon-audience-2x-total-width: 862px;
$icon-audience-2x-total-height: 696px;
$icon-audience-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-audience-2x: (396px, 514px, -396px, -514px, 32px, 32px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-audience@2x', );
$icon-clock-blue-2x-name: 'sprite-icon-clock-blue@2x';
$icon-clock-blue-2x-x: 532px;
$icon-clock-blue-2x-y: 404px;
$icon-clock-blue-2x-offset-x: -532px;
$icon-clock-blue-2x-offset-y: -404px;
$icon-clock-blue-2x-width: 30px;
$icon-clock-blue-2x-height: 30px;
$icon-clock-blue-2x-total-width: 862px;
$icon-clock-blue-2x-total-height: 696px;
$icon-clock-blue-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-clock-blue-2x: (532px, 404px, -532px, -404px, 30px, 30px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-clock-blue@2x', );
$icon-clock-2x-name: 'sprite-icon-clock@2x';
$icon-clock-2x-x: 788px;
$icon-clock-2x-y: 606px;
$icon-clock-2x-offset-x: -788px;
$icon-clock-2x-offset-y: -606px;
$icon-clock-2x-width: 38px;
$icon-clock-2x-height: 38px;
$icon-clock-2x-total-width: 862px;
$icon-clock-2x-total-height: 696px;
$icon-clock-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-clock-2x: (788px, 606px, -788px, -606px, 38px, 38px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-clock@2x', );
$icon-delivery-2x-name: 'sprite-icon-delivery@2x';
$icon-delivery-2x-x: 804px;
$icon-delivery-2x-y: 562px;
$icon-delivery-2x-offset-x: -804px;
$icon-delivery-2x-offset-y: -562px;
$icon-delivery-2x-width: 56px;
$icon-delivery-2x-height: 38px;
$icon-delivery-2x-total-width: 862px;
$icon-delivery-2x-total-height: 696px;
$icon-delivery-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-delivery-2x: (804px, 562px, -804px, -562px, 56px, 38px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-delivery@2x', );
$icon-ebay-2x-name: 'sprite-icon-ebay@2x';
$icon-ebay-2x-x: 564px;
$icon-ebay-2x-y: 0px;
$icon-ebay-2x-offset-x: -564px;
$icon-ebay-2x-offset-y: 0px;
$icon-ebay-2x-width: 298px;
$icon-ebay-2x-height: 88px;
$icon-ebay-2x-total-width: 862px;
$icon-ebay-2x-total-height: 696px;
$icon-ebay-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-ebay-2x: (564px, 0px, -564px, 0px, 298px, 88px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-ebay@2x', );
$icon-envelope-2x-name: 'sprite-icon-envelope@2x';
$icon-envelope-2x-x: 364px;
$icon-envelope-2x-y: 514px;
$icon-envelope-2x-offset-x: -364px;
$icon-envelope-2x-offset-y: -514px;
$icon-envelope-2x-width: 32px;
$icon-envelope-2x-height: 32px;
$icon-envelope-2x-total-width: 862px;
$icon-envelope-2x-total-height: 696px;
$icon-envelope-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-envelope-2x: (364px, 514px, -364px, -514px, 32px, 32px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-envelope@2x', );
$icon-facebook-logo-2x-name: 'sprite-icon-facebook-logo@2x';
$icon-facebook-logo-2x-x: 332px;
$icon-facebook-logo-2x-y: 582px;
$icon-facebook-logo-2x-offset-x: -332px;
$icon-facebook-logo-2x-offset-y: -582px;
$icon-facebook-logo-2x-width: 22px;
$icon-facebook-logo-2x-height: 38px;
$icon-facebook-logo-2x-total-width: 862px;
$icon-facebook-logo-2x-total-height: 696px;
$icon-facebook-logo-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-facebook-logo-2x: (332px, 582px, -332px, -582px, 22px, 38px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-facebook-logo@2x', );
$icon-facebook-round-2x-name: 'sprite-icon-facebook-round@2x';
$icon-facebook-round-2x-x: 798px;
$icon-facebook-round-2x-y: 500px;
$icon-facebook-round-2x-offset-x: -798px;
$icon-facebook-round-2x-offset-y: -500px;
$icon-facebook-round-2x-width: 60px;
$icon-facebook-round-2x-height: 60px;
$icon-facebook-round-2x-total-width: 862px;
$icon-facebook-round-2x-total-height: 696px;
$icon-facebook-round-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-facebook-round-2x: (798px, 500px, -798px, -500px, 60px, 60px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-facebook-round@2x', );
$icon-google-plus-2x-name: 'sprite-icon-google-plus@2x';
$icon-google-plus-2x-x: 484px;
$icon-google-plus-2x-y: 404px;
$icon-google-plus-2x-offset-x: -484px;
$icon-google-plus-2x-offset-y: -404px;
$icon-google-plus-2x-width: 48px;
$icon-google-plus-2x-height: 30px;
$icon-google-plus-2x-total-width: 862px;
$icon-google-plus-2x-total-height: 696px;
$icon-google-plus-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-google-plus-2x: (484px, 404px, -484px, -404px, 48px, 30px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-google-plus@2x', );
$icon-handshake-2x-name: 'sprite-icon-handshake@2x';
$icon-handshake-2x-x: 564px;
$icon-handshake-2x-y: 562px;
$icon-handshake-2x-offset-x: -564px;
$icon-handshake-2x-offset-y: -562px;
$icon-handshake-2x-width: 184px;
$icon-handshake-2x-height: 110px;
$icon-handshake-2x-total-width: 862px;
$icon-handshake-2x-total-height: 696px;
$icon-handshake-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-handshake-2x: (564px, 562px, -564px, -562px, 184px, 110px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-handshake@2x', );
$icon-heart-2x-name: 'sprite-icon-heart@2x';
$icon-heart-2x-x: 518px;
$icon-heart-2x-y: 434px;
$icon-heart-2x-offset-x: -518px;
$icon-heart-2x-offset-y: -434px;
$icon-heart-2x-width: 36px;
$icon-heart-2x-height: 32px;
$icon-heart-2x-total-width: 862px;
$icon-heart-2x-total-height: 696px;
$icon-heart-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-heart-2x: (518px, 434px, -518px, -434px, 36px, 32px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-heart@2x', );
$icon-etsy-2x-name: 'sprite-icon-etsy@2x';
$icon-etsy-2x-x: 564px;
$icon-etsy-2x-y: 352px;
$icon-etsy-2x-offset-x: -564px;
$icon-etsy-2x-offset-y: -352px;
$icon-etsy-2x-width: 298px;
$icon-etsy-2x-height: 88px;
$icon-etsy-2x-total-width: 862px;
$icon-etsy-2x-total-height: 696px;
$icon-etsy-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-etsy-2x: (564px, 352px, -564px, -352px, 298px, 88px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-etsy@2x', );
$icon-key-2x-name: 'sprite-icon-key@2x';
$icon-key-2x-x: 564px;
$icon-key-2x-y: 440px;
$icon-key-2x-offset-x: -564px;
$icon-key-2x-offset-y: -440px;
$icon-key-2x-width: 174px;
$icon-key-2x-height: 122px;
$icon-key-2x-total-width: 862px;
$icon-key-2x-total-height: 696px;
$icon-key-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-key-2x: (564px, 440px, -564px, -440px, 174px, 122px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-key@2x', );
$icon-li-check-2x-name: 'sprite-icon-li-check@2x';
$icon-li-check-2x-x: 564px;
$icon-li-check-2x-y: 672px;
$icon-li-check-2x-offset-x: -564px;
$icon-li-check-2x-offset-y: -672px;
$icon-li-check-2x-width: 28px;
$icon-li-check-2x-height: 24px;
$icon-li-check-2x-total-width: 862px;
$icon-li-check-2x-total-height: 696px;
$icon-li-check-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-li-check-2x: (564px, 672px, -564px, -672px, 28px, 24px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-li-check@2x', );
$icon-linkedin-2x-name: 'sprite-icon-linkedin@2x';
$icon-linkedin-2x-x: 520px;
$icon-linkedin-2x-y: 364px;
$icon-linkedin-2x-offset-x: -520px;
$icon-linkedin-2x-offset-y: -364px;
$icon-linkedin-2x-width: 34px;
$icon-linkedin-2x-height: 34px;
$icon-linkedin-2x-total-width: 862px;
$icon-linkedin-2x-total-height: 696px;
$icon-linkedin-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-linkedin-2x: (520px, 364px, -520px, -364px, 34px, 34px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-linkedin@2x', );
$icon-location-2x-name: 'sprite-icon-location@2x';
$icon-location-2x-x: 332px;
$icon-location-2x-y: 546px;
$icon-location-2x-offset-x: -332px;
$icon-location-2x-offset-y: -546px;
$icon-location-2x-width: 28px;
$icon-location-2x-height: 36px;
$icon-location-2x-total-width: 862px;
$icon-location-2x-total-height: 696px;
$icon-location-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-location-2x: (332px, 546px, -332px, -546px, 28px, 36px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-location@2x', );
$icon-message-2x-name: 'sprite-icon-message@2x';
$icon-message-2x-x: 826px;
$icon-message-2x-y: 606px;
$icon-message-2x-offset-x: -826px;
$icon-message-2x-offset-y: -606px;
$icon-message-2x-width: 34px;
$icon-message-2x-height: 34px;
$icon-message-2x-total-width: 862px;
$icon-message-2x-total-height: 696px;
$icon-message-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-message-2x: (826px, 606px, -826px, -606px, 34px, 34px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-message@2x', );
$icon-notification-blue-2x-name: 'sprite-icon-notification-blue@2x';
$icon-notification-blue-2x-x: 748px;
$icon-notification-blue-2x-y: 562px;
$icon-notification-blue-2x-offset-x: -748px;
$icon-notification-blue-2x-offset-y: -562px;
$icon-notification-blue-2x-width: 56px;
$icon-notification-blue-2x-height: 44px;
$icon-notification-blue-2x-total-width: 862px;
$icon-notification-blue-2x-total-height: 696px;
$icon-notification-blue-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-notification-blue-2x: (748px, 562px, -748px, -562px, 56px, 44px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-notification-blue@2x', );
$icon-pass-2x-name: 'sprite-icon-pass@2x';
$icon-pass-2x-x: 450px;
$icon-pass-2x-y: 514px;
$icon-pass-2x-offset-x: -450px;
$icon-pass-2x-offset-y: -514px;
$icon-pass-2x-width: 20px;
$icon-pass-2x-height: 32px;
$icon-pass-2x-total-width: 862px;
$icon-pass-2x-total-height: 696px;
$icon-pass-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-pass-2x: (450px, 514px, -450px, -514px, 20px, 32px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-pass@2x', );
$icon-phone-2x-name: 'sprite-icon-phone@2x';
$icon-phone-2x-x: 332px;
$icon-phone-2x-y: 514px;
$icon-phone-2x-offset-x: -332px;
$icon-phone-2x-offset-y: -514px;
$icon-phone-2x-width: 32px;
$icon-phone-2x-height: 32px;
$icon-phone-2x-total-width: 862px;
$icon-phone-2x-total-height: 696px;
$icon-phone-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-phone-2x: (332px, 514px, -332px, -514px, 32px, 32px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-phone@2x', );
$icon-piggy-bank-2x-name: 'sprite-icon-piggy-bank@2x';
$icon-piggy-bank-2x-x: 748px;
$icon-piggy-bank-2x-y: 606px;
$icon-piggy-bank-2x-offset-x: -748px;
$icon-piggy-bank-2x-offset-y: -606px;
$icon-piggy-bank-2x-width: 40px;
$icon-piggy-bank-2x-height: 44px;
$icon-piggy-bank-2x-total-width: 862px;
$icon-piggy-bank-2x-total-height: 696px;
$icon-piggy-bank-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-piggy-bank-2x: (748px, 606px, -748px, -606px, 40px, 44px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-piggy-bank@2x', );
$icon-pinterest-round-2x-name: 'sprite-icon-pinterest-round@2x';
$icon-pinterest-round-2x-x: 738px;
$icon-pinterest-round-2x-y: 440px;
$icon-pinterest-round-2x-offset-x: -738px;
$icon-pinterest-round-2x-offset-y: -440px;
$icon-pinterest-round-2x-width: 60px;
$icon-pinterest-round-2x-height: 60px;
$icon-pinterest-round-2x-total-width: 862px;
$icon-pinterest-round-2x-total-height: 696px;
$icon-pinterest-round-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-pinterest-round-2x: (738px, 440px, -738px, -440px, 60px, 60px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-pinterest-round@2x', );
$icon-play-2x-name: 'sprite-icon-play@2x';
$icon-play-2x-x: 0px;
$icon-play-2x-y: 0px;
$icon-play-2x-offset-x: 0px;
$icon-play-2x-offset-y: 0px;
$icon-play-2x-width: 332px;
$icon-play-2x-height: 332px;
$icon-play-2x-total-width: 862px;
$icon-play-2x-total-height: 696px;
$icon-play-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-play-2x: (0px, 0px, 0px, 0px, 332px, 332px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-play@2x', );
$icon-quote-purple-2x-name: 'sprite-icon-quote-purple@2x';
$icon-quote-purple-2x-x: 776px;
$icon-quote-purple-2x-y: 650px;
$icon-quote-purple-2x-offset-x: -776px;
$icon-quote-purple-2x-offset-y: -650px;
$icon-quote-purple-2x-width: 20px;
$icon-quote-purple-2x-height: 18px;
$icon-quote-purple-2x-total-width: 862px;
$icon-quote-purple-2x-total-height: 696px;
$icon-quote-purple-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-quote-purple-2x: (776px, 650px, -776px, -650px, 20px, 18px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-quote-purple@2x', );
$icon-scissor-2x-name: 'sprite-icon-scissor@2x';
$icon-scissor-2x-x: 370px;
$icon-scissor-2x-y: 484px;
$icon-scissor-2x-offset-x: -370px;
$icon-scissor-2x-offset-y: -484px;
$icon-scissor-2x-width: 36px;
$icon-scissor-2x-height: 28px;
$icon-scissor-2x-total-width: 862px;
$icon-scissor-2x-total-height: 696px;
$icon-scissor-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-scissor-2x: (370px, 484px, -370px, -484px, 36px, 28px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-scissor@2x', );
$icon-share-2x-name: 'sprite-icon-share@2x';
$icon-share-2x-x: 484px;
$icon-share-2x-y: 364px;
$icon-share-2x-offset-x: -484px;
$icon-share-2x-offset-y: -364px;
$icon-share-2x-width: 36px;
$icon-share-2x-height: 40px;
$icon-share-2x-total-width: 862px;
$icon-share-2x-total-height: 696px;
$icon-share-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-share-2x: (484px, 364px, -484px, -364px, 36px, 40px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-share@2x', );
$icon-shopify-2x-name: 'sprite-icon-shopify@2x';
$icon-shopify-2x-x: 564px;
$icon-shopify-2x-y: 264px;
$icon-shopify-2x-offset-x: -564px;
$icon-shopify-2x-offset-y: -264px;
$icon-shopify-2x-width: 298px;
$icon-shopify-2x-height: 88px;
$icon-shopify-2x-total-width: 862px;
$icon-shopify-2x-total-height: 696px;
$icon-shopify-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-shopify-2x: (564px, 264px, -564px, -264px, 298px, 88px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-shopify@2x', );
$icon-sign-up-buyer-2x-name: 'sprite-icon-sign-up-buyer@2x';
$icon-sign-up-buyer-2x-x: 332px;
$icon-sign-up-buyer-2x-y: 0px;
$icon-sign-up-buyer-2x-offset-x: -332px;
$icon-sign-up-buyer-2x-offset-y: 0px;
$icon-sign-up-buyer-2x-width: 232px;
$icon-sign-up-buyer-2x-height: 364px;
$icon-sign-up-buyer-2x-total-width: 862px;
$icon-sign-up-buyer-2x-total-height: 696px;
$icon-sign-up-buyer-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-sign-up-buyer-2x: (332px, 0px, -332px, 0px, 232px, 364px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-sign-up-buyer@2x', );
$icon-sign-up-seller-2x-name: 'sprite-icon-sign-up-seller@2x';
$icon-sign-up-seller-2x-x: 0px;
$icon-sign-up-seller-2x-y: 332px;
$icon-sign-up-seller-2x-offset-x: 0px;
$icon-sign-up-seller-2x-offset-y: -332px;
$icon-sign-up-seller-2x-width: 232px;
$icon-sign-up-seller-2x-height: 364px;
$icon-sign-up-seller-2x-total-width: 862px;
$icon-sign-up-seller-2x-total-height: 696px;
$icon-sign-up-seller-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-sign-up-seller-2x: (0px, 332px, 0px, -332px, 232px, 364px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-sign-up-seller@2x', );
$icon-tw-round-2x-name: 'sprite-icon-tw-round@2x';
$icon-tw-round-2x-x: 798px;
$icon-tw-round-2x-y: 440px;
$icon-tw-round-2x-offset-x: -798px;
$icon-tw-round-2x-offset-y: -440px;
$icon-tw-round-2x-width: 60px;
$icon-tw-round-2x-height: 60px;
$icon-tw-round-2x-total-width: 862px;
$icon-tw-round-2x-total-height: 696px;
$icon-tw-round-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-tw-round-2x: (798px, 440px, -798px, -440px, 60px, 60px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-tw-round@2x', );
$icon-twitter-logo-2x-name: 'sprite-icon-twitter-logo@2x';
$icon-twitter-logo-2x-x: 332px;
$icon-twitter-logo-2x-y: 484px;
$icon-twitter-logo-2x-offset-x: -332px;
$icon-twitter-logo-2x-offset-y: -484px;
$icon-twitter-logo-2x-width: 38px;
$icon-twitter-logo-2x-height: 30px;
$icon-twitter-logo-2x-total-width: 862px;
$icon-twitter-logo-2x-total-height: 696px;
$icon-twitter-logo-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-twitter-logo-2x: (332px, 484px, -332px, -484px, 38px, 30px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-twitter-logo@2x', );
$icon-user-2x-name: 'sprite-icon-user@2x';
$icon-user-2x-x: 428px;
$icon-user-2x-y: 514px;
$icon-user-2x-offset-x: -428px;
$icon-user-2x-offset-y: -514px;
$icon-user-2x-width: 22px;
$icon-user-2x-height: 32px;
$icon-user-2x-total-width: 862px;
$icon-user-2x-total-height: 696px;
$icon-user-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-user-2x: (428px, 514px, -428px, -514px, 22px, 32px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-user@2x', );
$icon-voucher-2x-name: 'sprite-icon-voucher@2x';
$icon-voucher-2x-x: 332px;
$icon-voucher-2x-y: 364px;
$icon-voucher-2x-offset-x: -332px;
$icon-voucher-2x-offset-y: -364px;
$icon-voucher-2x-width: 152px;
$icon-voucher-2x-height: 120px;
$icon-voucher-2x-total-width: 862px;
$icon-voucher-2x-total-height: 696px;
$icon-voucher-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-voucher-2x: (332px, 364px, -332px, -364px, 152px, 120px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-voucher@2x', );
$icon-walmart-2x-name: 'sprite-icon-walmart@2x';
$icon-walmart-2x-x: 564px;
$icon-walmart-2x-y: 176px;
$icon-walmart-2x-offset-x: -564px;
$icon-walmart-2x-offset-y: -176px;
$icon-walmart-2x-width: 298px;
$icon-walmart-2x-height: 88px;
$icon-walmart-2x-total-width: 862px;
$icon-walmart-2x-total-height: 696px;
$icon-walmart-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-walmart-2x: (564px, 176px, -564px, -176px, 298px, 88px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-walmart@2x', );
$icon-whatsapp-round-2x-name: 'sprite-icon-whatsapp-round@2x';
$icon-whatsapp-round-2x-x: 738px;
$icon-whatsapp-round-2x-y: 500px;
$icon-whatsapp-round-2x-offset-x: -738px;
$icon-whatsapp-round-2x-offset-y: -500px;
$icon-whatsapp-round-2x-width: 60px;
$icon-whatsapp-round-2x-height: 60px;
$icon-whatsapp-round-2x-total-width: 862px;
$icon-whatsapp-round-2x-total-height: 696px;
$icon-whatsapp-round-2x-image: '../img/sprites/spritesheet-@2x.png';
$icon-whatsapp-round-2x: (738px, 500px, -738px, -500px, 60px, 60px, 862px, 696px, '../img/sprites/spritesheet-@2x.png', 'sprite-icon-whatsapp-round@2x', );
$spritesheet-width: 431px;
$spritesheet-height: 348px;
$spritesheet-image: '../img/sprites/spritesheet.png';
$spritesheet-sprites: ($icon-amazon, $icon-arrow-r, $icon-attach, $icon-audience, $icon-clock-blue, $icon-clock, $icon-delivery, $icon-ebay, $icon-envelope, $icon-facebook-logo, $icon-facebook-round, $icon-google-plus, $icon-handshake, $icon-heart, $icon-etsy, $icon-key, $icon-li-check, $icon-linkedin, $icon-location, $icon-message, $icon-notification-blue, $icon-pass, $icon-phone, $icon-piggy-bank, $icon-pinterest-round, $icon-play, $icon-quote-purple, $icon-scissor, $icon-share, $icon-shopify, $icon-sign-up-buyer, $icon-sign-up-seller, $icon-tw-round, $icon-twitter-logo, $icon-user, $icon-voucher, $icon-walmart, $icon-whatsapp-round, );
$spritesheet: (431px, 348px, '../img/sprites/spritesheet.png', $spritesheet-sprites, );
$retina-spritesheet-width: 862px;
$retina-spritesheet-height: 696px;
$retina-spritesheet-image: '../img/sprites/spritesheet-@2x.png';
$retina-spritesheet-sprites: ($icon-amazon-2x, $icon-arrow-r-2x, $icon-attach-2x, $icon-audience-2x, $icon-clock-blue-2x, $icon-clock-2x, $icon-delivery-2x, $icon-ebay-2x, $icon-envelope-2x, $icon-facebook-logo-2x, $icon-facebook-round-2x, $icon-google-plus-2x, $icon-handshake-2x, $icon-heart-2x, $icon-etsy-2x, $icon-key-2x, $icon-li-check-2x, $icon-linkedin-2x, $icon-location-2x, $icon-message-2x, $icon-notification-blue-2x, $icon-pass-2x, $icon-phone-2x, $icon-piggy-bank-2x, $icon-pinterest-round-2x, $icon-play-2x, $icon-quote-purple-2x, $icon-scissor-2x, $icon-share-2x, $icon-shopify-2x, $icon-sign-up-buyer-2x, $icon-sign-up-seller-2x, $icon-tw-round-2x, $icon-twitter-logo-2x, $icon-user-2x, $icon-voucher-2x, $icon-walmart-2x, $icon-whatsapp-round-2x, );
$retina-spritesheet: (862px, 696px, '../img/sprites/spritesheet-@2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$icon-amazon-group-name: 'sprite-icon-amazon';
$icon-amazon-group: ('sprite-icon-amazon', $icon-amazon, $icon-amazon-2x, );
$icon-arrow-r-group-name: 'sprite-icon-arrow-r';
$icon-arrow-r-group: ('sprite-icon-arrow-r', $icon-arrow-r, $icon-arrow-r-2x, );
$icon-attach-group-name: 'sprite-icon-attach';
$icon-attach-group: ('sprite-icon-attach', $icon-attach, $icon-attach-2x, );
$icon-audience-group-name: 'sprite-icon-audience';
$icon-audience-group: ('sprite-icon-audience', $icon-audience, $icon-audience-2x, );
$icon-clock-blue-group-name: 'sprite-icon-clock-blue';
$icon-clock-blue-group: ('sprite-icon-clock-blue', $icon-clock-blue, $icon-clock-blue-2x, );
$icon-clock-group-name: 'sprite-icon-clock';
$icon-clock-group: ('sprite-icon-clock', $icon-clock, $icon-clock-2x, );
$icon-delivery-group-name: 'sprite-icon-delivery';
$icon-delivery-group: ('sprite-icon-delivery', $icon-delivery, $icon-delivery-2x, );
$icon-ebay-group-name: 'sprite-icon-ebay';
$icon-ebay-group: ('sprite-icon-ebay', $icon-ebay, $icon-ebay-2x, );
$icon-envelope-group-name: 'sprite-icon-envelope';
$icon-envelope-group: ('sprite-icon-envelope', $icon-envelope, $icon-envelope-2x, );
$icon-facebook-logo-group-name: 'sprite-icon-facebook-logo';
$icon-facebook-logo-group: ('sprite-icon-facebook-logo', $icon-facebook-logo, $icon-facebook-logo-2x, );
$icon-facebook-round-group-name: 'sprite-icon-facebook-round';
$icon-facebook-round-group: ('sprite-icon-facebook-round', $icon-facebook-round, $icon-facebook-round-2x, );
$icon-google-plus-group-name: 'sprite-icon-google-plus';
$icon-google-plus-group: ('sprite-icon-google-plus', $icon-google-plus, $icon-google-plus-2x, );
$icon-handshake-group-name: 'sprite-icon-handshake';
$icon-handshake-group: ('sprite-icon-handshake', $icon-handshake, $icon-handshake-2x, );
$icon-heart-group-name: 'sprite-icon-heart';
$icon-heart-group: ('sprite-icon-heart', $icon-heart, $icon-heart-2x, );
$icon-etsy-group-name: 'sprite-icon-etsy';
$icon-etsy-group: ('sprite-icon-etsy', $icon-etsy, $icon-etsy-2x, );
$icon-key-group-name: 'sprite-icon-key';
$icon-key-group: ('sprite-icon-key', $icon-key, $icon-key-2x, );
$icon-li-check-group-name: 'sprite-icon-li-check';
$icon-li-check-group: ('sprite-icon-li-check', $icon-li-check, $icon-li-check-2x, );
$icon-linkedin-group-name: 'sprite-icon-linkedin';
$icon-linkedin-group: ('sprite-icon-linkedin', $icon-linkedin, $icon-linkedin-2x, );
$icon-location-group-name: 'sprite-icon-location';
$icon-location-group: ('sprite-icon-location', $icon-location, $icon-location-2x, );
$icon-message-group-name: 'sprite-icon-message';
$icon-message-group: ('sprite-icon-message', $icon-message, $icon-message-2x, );
$icon-notification-blue-group-name: 'sprite-icon-notification-blue';
$icon-notification-blue-group: ('sprite-icon-notification-blue', $icon-notification-blue, $icon-notification-blue-2x, );
$icon-pass-group-name: 'sprite-icon-pass';
$icon-pass-group: ('sprite-icon-pass', $icon-pass, $icon-pass-2x, );
$icon-phone-group-name: 'sprite-icon-phone';
$icon-phone-group: ('sprite-icon-phone', $icon-phone, $icon-phone-2x, );
$icon-piggy-bank-group-name: 'sprite-icon-piggy-bank';
$icon-piggy-bank-group: ('sprite-icon-piggy-bank', $icon-piggy-bank, $icon-piggy-bank-2x, );
$icon-pinterest-round-group-name: 'sprite-icon-pinterest-round';
$icon-pinterest-round-group: ('sprite-icon-pinterest-round', $icon-pinterest-round, $icon-pinterest-round-2x, );
$icon-play-group-name: 'sprite-icon-play';
$icon-play-group: ('sprite-icon-play', $icon-play, $icon-play-2x, );
$icon-quote-purple-group-name: 'sprite-icon-quote-purple';
$icon-quote-purple-group: ('sprite-icon-quote-purple', $icon-quote-purple, $icon-quote-purple-2x, );
$icon-scissor-group-name: 'sprite-icon-scissor';
$icon-scissor-group: ('sprite-icon-scissor', $icon-scissor, $icon-scissor-2x, );
$icon-share-group-name: 'sprite-icon-share';
$icon-share-group: ('sprite-icon-share', $icon-share, $icon-share-2x, );
$icon-shopify-group-name: 'sprite-icon-shopify';
$icon-shopify-group: ('sprite-icon-shopify', $icon-shopify, $icon-shopify-2x, );
$icon-sign-up-buyer-group-name: 'sprite-icon-sign-up-buyer';
$icon-sign-up-buyer-group: ('sprite-icon-sign-up-buyer', $icon-sign-up-buyer, $icon-sign-up-buyer-2x, );
$icon-sign-up-seller-group-name: 'sprite-icon-sign-up-seller';
$icon-sign-up-seller-group: ('sprite-icon-sign-up-seller', $icon-sign-up-seller, $icon-sign-up-seller-2x, );
$icon-tw-round-group-name: 'sprite-icon-tw-round';
$icon-tw-round-group: ('sprite-icon-tw-round', $icon-tw-round, $icon-tw-round-2x, );
$icon-twitter-logo-group-name: 'sprite-icon-twitter-logo';
$icon-twitter-logo-group: ('sprite-icon-twitter-logo', $icon-twitter-logo, $icon-twitter-logo-2x, );
$icon-user-group-name: 'sprite-icon-user';
$icon-user-group: ('sprite-icon-user', $icon-user, $icon-user-2x, );
$icon-voucher-group-name: 'sprite-icon-voucher';
$icon-voucher-group: ('sprite-icon-voucher', $icon-voucher, $icon-voucher-2x, );
$icon-walmart-group-name: 'sprite-icon-walmart';
$icon-walmart-group: ('sprite-icon-walmart', $icon-walmart, $icon-walmart-2x, );
$icon-whatsapp-round-group-name: 'sprite-icon-whatsapp-round';
$icon-whatsapp-round-group: ('sprite-icon-whatsapp-round', $icon-whatsapp-round, $icon-whatsapp-round-2x, );
$retina-groups: ($icon-amazon-group, $icon-arrow-r-group, $icon-attach-group, $icon-audience-group, $icon-clock-blue-group, $icon-clock-group, $icon-delivery-group, $icon-ebay-group, $icon-envelope-group, $icon-facebook-logo-group, $icon-facebook-round-group, $icon-google-plus-group, $icon-handshake-group, $icon-heart-group, $icon-etsy-group, $icon-key-group, $icon-li-check-group, $icon-linkedin-group, $icon-location-group, $icon-message-group, $icon-notification-blue-group, $icon-pass-group, $icon-phone-group, $icon-piggy-bank-group, $icon-pinterest-round-group, $icon-play-group, $icon-quote-purple-group, $icon-scissor-group, $icon-share-group, $icon-shopify-group, $icon-sign-up-buyer-group, $icon-sign-up-seller-group, $icon-tw-round-group, $icon-twitter-logo-group, $icon-user-group, $icon-voucher-group, $icon-walmart-group, $icon-whatsapp-round-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('sprite-icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
