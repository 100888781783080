.expandable {
  position: relative;
  padding-bottom: 2rem;

  .expandable-content {
    position: relative;
    height: 8em;
    overflow: hidden;

    &.expandable-content-sm {
      height: 5em;
    }

    &.expandable-content-lg {
      height: 12em;
    }

    &.expandable-content-xl {
      height: 15em;
    }
  }

  .expandable-indicator {
    display: none;
    position: absolute;
    width: 100%;
    height: 3em;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255,0) 0,rgba(255, 255, 255, 1) 100%);

    &.expandable-indicator-gray {
      background: linear-gradient(to bottom, rgba(228, 229, 230, 0) 0, rgba(228, 229, 230, 1) 100%);
    }
  }

  .expandable-trigger-more {
    border: 0;
    display: none;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .btn-outline-discreet,
  .btn-outline-dark {
    padding: 0.3rem 0.5rem;
  }
}