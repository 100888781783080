.sign-up {
  header.navbar.navbar-light .navbar-brand {
    position: static;
    margin: 0;
  }
}

.line {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #f3f3f3;

  + span {
    white-space: nowrap;
  }
}

.sign-up-list {
  span {
    background: #20a8d8; /* Old browsers */
    @include rounded-50;
    font-weight: bold;
    min-width: calc-rem(29px);
    height: calc-rem(29px);
    text-align: center;
    color: $white;
    padding-top: calc-rem(2px);
  }
}

.social-login-buttons {
  a,
  button {
    color: $white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &:hover {
      color: $white;
    }

    &.bg-amazon {
      color: $black;
      background: #FF9900;
    }

    &.bg-apple {
      color: $white;
      background: $black;
    }

    &:active,
    &:focus {
      &.bg-facebook {
        box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq(#475893), #475893, 15%), .5);
      }

      &.bg-google-plus {
        box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq(#d34836), #d34836, 15%), .5);
      }

      &.bg-amazon {
        box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq(#FF9900), #FF9900, 15%), .5);
      }

      &.bg-apple {
        box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix(color-yiq(#000), #000, 15%), .5);
      }
    }
  }
}