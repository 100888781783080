.stripe-button-el {
  border: 1px solid #20a8d8 !important;
  transition: .2s ease-out !important;
  color: #fff !important;
  margin: 0 !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  text-transform: none !important;
  background: #20a8d8 !important;
  padding: 0 !important;
  border-radius: 0 !important;

  &:hover, &:focus {
    background-color: #1b8eb7 !important;
    box-shadow: none !important;
    border: 1px solid #1985ac !important;
  }

  span {
    background: transparent !important;
    padding: 0.4rem 0.75rem !important;
    border-radius: 0 !important;
    font-size: 0.875rem !important;
    height: auto !important;
    line-height: inherit !important;
    font-weight: normal !important;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }
}

.stripe-button-lg .stripe-button-el {
  vertical-align: middle;

  span {
    padding: .5rem 1rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }
}

.btn-xl {
  padding: 1rem 2rem;
  font-size: 2rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.4rem;
  }
}