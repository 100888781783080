footer {

  h5 {
    font-weight: bold;
    font-size: 1.375rem;
    line-height: 1.59;
  }

  .list-unstyled {
    a {
      color: $body-color;

      &:hover,
      &:active {
        color: darken($body-color, 15%);
      }
    }

    &.social-buttons {
      justify-content: center;

      a {
        width: calc-rem(40px);
        height: calc-rem(40px);
        display: flex;
        text-decoration: none;
        @include rounded-50;

        i {
          margin: auto;
        }
      }
    }
  }

  &.app-footer {
    flex: 0 0 51px;
    height: 51px;
  }
}