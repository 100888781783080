.slick-slider {
  .slick-prev,
  .slick-next {
    margin: auto;
    width: 25px;
    height: 40px;

    &:before {
      color: $grey;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      font-size: 2.5rem;
    }
  }

  .slick-prev {
    left: 0;
    &:before {
      content: "\f053";
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: "\f054";
    }
  }
}