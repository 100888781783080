:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.btn-outline-light {
  color: inherit;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: bold;
  color: $dark;
  font-family: Roboto, sans-serif;
}

h4, .h4 {
  line-height: 1.59;
}

.strikethrough {
  text-decoration: line-through;
}

.lato-medium {
  font-family: 'Lato Medium', sans-serif;
  font-weight: 500;
}

.lato-lighter {
  font-family: 'Lato Light', sans-serif;
  font-weight: 400;
}

.roboto-medium {
  font-family: 'Roboto-Medium', sans-serif;
  font-weight: 500;
}

.small-text {
  font-size: $font-size-sm;
}

.fa-10x {
  font-size: 10em;
}

.fa-15x {
  font-size: 15em;
}

.fa-20x {
  font-size: 20em;
}

.none {
  display: none;
}

.with-shadow {
  @include block-box-shadow;
}

.with-small-shadow {
  @include block-box-small-shadow;
}

.about-us-icon {
  width: calc-rem(114px);
  height: calc-rem(114px);
  background: $white;
  @include rounded-50;
  @include block-box-middle-shadow;
  top: calc-rem(-57px);
}

#marketplaces {
  @include media-breakpoint-down(sm) {
    .logos {
      > div {
        width: 100%;
        margin: 20px 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .logos {
      > div {
        width: calc(33% - 40px);
        margin: 20px;
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.nice-accordion {
  .card {
    border: 1px solid #f3f3f3;

    .card-header {
      background-color: transparent;
      border: 0;

      h5 {
        font-size: $font-size-base;

        a {
          color: $primary;
          transition: $transition-base;

          .accordion-caret {
            background: url("../img/icons/caret-up.png") no-repeat;
            width: calc-rem(13px);
            min-width: calc-rem(13px);
            height: calc-rem(8px);
          }

          &.collapsed {
            background: $ghostwhite;
            color: $dark;

            .accordion-caret {
              background: url("../img/icons/caret-down.png") no-repeat;
            }
          }

          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}