#buyers-testimonials {
  background-color: $ghostwhite;

  .card {
    img {
      width: calc-rem(90px);
      height: calc-rem(90px);
      @include rounded-50;
    }

    .testimonial-icon {
      width: calc-rem(33px);
      height: calc-rem(33px);
      position: absolute;
      @include rounded-50;
      top: calc-rem(57px);
      left: calc-rem(73px);
    }

    @include media-breakpoint-down(xs) {
      img {
        width: 4.625rem;
        height: 4.625rem;
      }
      .testimonial-icon {
        width: 1.625rem;
        height: 1.625rem;
        top: 2.5625rem;
        left: 3.5625rem;
      }

      h4.text-dark {
        margin-left: 5.5125rem !important;
      }
    }
  }

  &.best-buyer-testimonials {
    .expandable-content-inner {
      @include media-breakpoint-up(md) {
        min-height: 4rem;
      }

      @include media-breakpoint-up(lg) {
        min-height: 10.5rem;
      }
    }
  }
}