@mixin bg-base-property {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@mixin icon-base-property {
  display: inline-block;
  @include bg-base-property;
}
@mixin rotate-180 {
  -webkit-transform:  rotate(180deg);
  -moz-transform:  rotate(180deg);
  -ms-transform:  rotate(180deg);
  -o-transform:  rotate(180deg);
  transform:  rotate(180deg);
}
@mixin bg-light-primary {
  background-image: linear-gradient(to bottom, $white, $duck-egg-blue-two);
}
@mixin slyle-on-scroll {
  -webkit-box-shadow: 0 0 4px 1px rgba($steel, 0.5);
  box-shadow: 0 0 4px 1px rgba($steel, 0.5);
}

@mixin block-box-shadow {
  -webkit-box-shadow:  0 5px 25px 0 rgba(0, 0, 0, 0.1);
  box-shadow:  0 5px 25px 0 rgba(0, 0, 0, 0.1);
}

@mixin block-box-small-shadow {
  -webkit-box-shadow:  0 1px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow:  0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

@mixin block-box-middle-shadow {
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

@mixin rounded-50 {
  border-radius: 50%;
}

@mixin text-shadow {
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@mixin sm-img-box-shadow {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.14);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.14);
}

@mixin dropdown-box-shadow {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

@mixin position-stiky {
  position: sticky;
}

@mixin grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@mixin grayscale-none {
  -webkit-filter: none;
  filter: none;
}
@mixin absolute-left {
  position: absolute;
  left: 0;
}