#chrome-extension-hero {

  .row {
    background-color: #f9fbfe;

    .chrome-savings {
      background-color: #20a8d8;

      img {
        margin-bottom: -100px;
        margin-left: -400px;
        width: 1000px;

        @include media-breakpoint-up(md) {
          margin-left: -530px;
        }

        @include media-breakpoint-up(lg) {
          margin-left: -400px;
        }

        @include media-breakpoint-up(xl) {
          margin-left: -300px;
        }

        @include media-breakpoint-up(xxl) {
          margin-left: -150px;
        }

        @include media-breakpoint-up(xxl) {
          margin-left: -225px;
        }
      }
    }

    .chrome-start {
      background: transparent url('../img/chrome/map.png') 0 0 no-repeat padding-box;
    }
  }
}

#chrome-video-explanations {
  .video {
    padding: 1px;
    background-color: #d3e0e9;
    border-radius: $border-radius;
  }
}