section {
  &.bg-landing {
    background: url("../img/bg/landing-main-bg.jpg") no-repeat center;
    background-size: cover;
  }
}

.map-bg {
  background: url("../img/bg/map-bg.png") no-repeat center;
  background-size: auto;
}

.about-us-bg {
  background: url("../img/bg/about-us-bg.jpg") no-repeat center;
  background-size: cover;
}

.main-bg {
  background: url('../img/bg/home-hero.jpg') no-repeat left top;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    background: url('../img/bg/home-hero.jpg') no-repeat center top;
    background-size: cover;
  }

  @include media-breakpoint-up(xxl) {
    background-position: center 14%;
  }

  @include media-breakpoint-up(uxxl) {
    background-position: center 18%;
  }
}

.testimonial-bg {
  background: url("../img/bg/testimonial-bg.jpg") no-repeat center;
  background-size: cover;
}

.gradient-bg {
  background: #f9fbfe; /* Old browsers */
  background: -moz-linear-gradient(top, #f9fbfe 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f9fbfe 0%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #f9fbfe 0%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9fbfe', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
}

.message-bg {
  background: url("../img/bg/messages-bg.png") no-repeat bottom center;
  background-size: 100% auto;
}

.sign-up-bg {
  background: url("../img/bg/sign-up-bg.jpg") no-repeat calc-rem(100px) 100%;
  background-size: cover;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  padding-left: 120px;

  @include media-breakpoint-up(xl) {
    z-index: 1021;
  }
}

.affiliate-bg {
  background: url("../img/bg/affiliate-bg.jpg") no-repeat center;
  background-size: cover;
}

.pc-bg {
  background: url("../img/bg/pc-bg.png") no-repeat right;
  background-size: auto;
}

.dollar-bg {
  background: url("../img/bg/dollar-bg.png") no-repeat center;
  background-size: 100% auto;
}


@media (max-width: 1700px) {
  .pc-bg {
    background: url("../img/bg/pc-bg.png") no-repeat right;
    background-size: 45%;
  }
}


@media (max-width: 1500px) {
  .sign-up-bg {
    background-position: 2.25rem 100%;
    padding-left: 2.5rem;
  }
}

@include media-breakpoint-up(xl) {
  .message-bg {
    background-size: auto;
  }
}

@include media-breakpoint-down(lg) {
  .sign-up-bg {
    background-position: 0 100%;
    position: relative;
    height: auto;
    width: 100%;
    border-radius: $border-radius;
    display: flex;
    padding-left: 0;
    margin-bottom: calc-rem(30px);
  }
}

@include media-breakpoint-down(md) {
  .pc-bg {
    background: none;
  }
}

@include media-breakpoint-down(sm) {
  .message-bg, .dollar-bg {
    background: none;
  }
}