.retailer {
  position: relative;
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid #f3f3f3;
  border-radius: .3125rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

  &.retailer-item {
    margin-bottom: $spacer;
  }

  &:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  }

  .lozad {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .line {
    width: calc(100% + 1.8rem);
    margin-left: -.9rem;
    margin-bottom: .625rem;
    height: 18px;
    display: block;
    background: url(../img/separator.png) no-repeat;
    background-size: cover;
  }

  .title {
    font-size: $font-size-base;

    a {
      color: $dark;
      font-family: 'Roboto-Medium', sans-serif;
      font-weight: 500;
    }
  }

  .offers {
    display: inline-block;
  }

  .percent {
    font-size: $font-size-base;
    text-transform: uppercase;
    border-radius: $border-radius;
    padding: calc-rem(5px) calc-rem(10px);
    white-space: nowrap;
    color: $white;

    @include media-breakpoint-down(lg) {
      padding: calc-rem(5px);
    }
  }

  .retailer-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.2rem;

    .retailer-title {
      font-size: 1.2rem;
    }

    > div {
      margin-left: 10px;

      .fa {
        cursor: pointer;
      }

      &.release {
        color: #dadada;
      }

      &.share {
        position: relative;
        color: #dadada;
        max-height: 28px;
        z-index: 10;

        > div {
          position: absolute;
          top: 100%;
          left: -5px;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 5px;
          border-radius: 0.5rem;

          .fab:hover {
            color: darken(#dadada, 15%);
          }
        }
      }
    }
  }

  .preview {
    display: block;
    position: relative;
    margin-bottom: $spacer;
    height: 225px;

    .loader {
      color: $grey;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      flex-direction: column;
      background-color: #fff;
      text-align: center;
      height: 225px;
    }

    .lozad {
      height: 225px;

      &[data-loaded] {
        + .loader {
          display: none;
        }
      }
    }
  }
}