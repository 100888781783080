.page-load-status {
    display: none;
    padding: $spacer 0;
    border-top: 1px solid #c2cfd6;
    text-align: center;
    color: #777;

    .loader-ellipse {
        font-size: 20px;
        position: relative;
        width: 4em;
        height: 1em;
        margin: 10px auto;

        .loader-ellipse-dot {
            display: block;
            width: 1em;
            height: 1em;
            border-radius: 0.5em;
            background: #777;
            position: absolute;
            animation-duration: 0.5s;
            animation-timing-function: ease;
            animation-iteration-count: infinite;
        }

        .loader-ellipse-dot:nth-child(1),
        .loader-ellipse-dot:nth-child(2) {
            left: 0;
        }
        .loader-ellipse-dot:nth-child(3) {
            left: 1.5em;
        }
        .loader-ellipse-dot:nth-child(4) {
            left: 3em;
        }

        @keyframes reveal {
            from {
                transform: scale(0.001);
            }
            to {
                transform: scale(1);
            }
        }

        @keyframes slide {
            to {
                transform: translateX(1.5em)
            }
        }

        .loader-ellipse-dot:nth-child(1) {
            animation-name: reveal;
        }

        .loader-ellipse-dot:nth-child(2),
        .loader-ellipse-dot:nth-child(3) {
            animation-name: slide;
        }

        .loader-ellipse-dot:nth-child(4) {
            animation-name: reveal;
            animation-direction: reverse;
        }
    }
}