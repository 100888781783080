.alert {

  &.alert-success,
  &.alert-danger,
  &.alert-info,
  &.alert-secondary {
    a {
      color: $white;
    }
  }

  &.inverse i {
    border-radius: 0;
  }

  &.alert-notify {

    &.alert-dismissible {
      padding-right: 1.25rem;
    }

    .close {
      opacity: 1;
      position: absolute;
      right: -15px !important;
      top: -15px !important;
      background-color: #2a3142;
      color: #fff;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-shadow: none;
      padding: 0;

      &:hover {
        color: #fff;
      }
    }
  }
}