// Steppers

// Common styles for steppers

.stepper {
  li {
    > a {
      padding: 10px;
      font-size: 14px;
      text-align: center;
      text-decoration: none;
      .circle {
        display: inline-block;
        color: #fff;
        border-radius: 50%;
        background: rgba(0, 0, 0, .38);
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        margin-right: 8px;
        position: relative;
        z-index: 2;
      }
      .label {
        display: inline-block;
      }

      &:hover .circle {
        background-color: #20a8d8;
      }

      &.disabled {
        cursor: default;

        .circle {
          background: rgba(0, 0, 0, .38);
        }
      }
    }
    &.active, &.completed {
      > a {
        .circle {
          background-color: theme-color("primary");
        }
        .label {
          color: rgba(0, 0, 0, .87);
        }
      }
    }
    &.warning {
      > a {
        .circle {
          background-color: theme-color("danger");
        }
      }
    }
  }
}

// Horizontal

.stepper-horizontal {
  position: relative;
  display: flex;
  justify-content: space-between;
  li {
    display: flex;
    align-items: center;
    flex: 1;
    &:not(:last-child):after {
      content: '';
      position: relative;
      flex: 1;
      margin: 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    &:not(:first-child):before {
      content: '';
      position: relative;
      flex: 1;
      margin: 0 0;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

// Vertical

.stepper-vertical {

  li {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100px;

    > a {
      align-self: flex-start;
      display: flex;
      position: relative;
      font-size: 18px;
      align-items: center;

      .circle {
        order: 1;
        width: 36px;
        min-width: 36px;
        height: 36px;
        line-height: 36px;
      }

      .label {
        flex-flow: column nowrap;
        order: 2;
      }

    }
    &.completed {
      a {
        .label {
          font-weight: 500;
        }
      }
    }
    .step-content {
      margin-top: 0;
      margin-left: 50px;
      padding: 15px;
      p {
        font-size: 0.875rem;
      }
    }
    &:not(:last-child):after {
      content: " ";
      position: absolute;
      width: 1px;
      height: calc(100% - 34px);
      left: 28px;
      top: 46px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}