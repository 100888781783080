//
// Variables
// --------------------------------------------------


// Variables directly translating Bootstrap variables
// -------------------------

$s2bs-border-radius: $border-radius !default;
$s2bs-border-radius-lg: $border-radius-lg !default;
$s2bs-border-radius-sm: $border-radius-sm !default;
$s2bs-btn-bg: theme-color("info") !default;
$s2bs-btn-border-color: theme-color("info") !default;
$s2bs-btn-color: $white !default;
$s2bs-dropdown-header-color: $dropdown-header-color !default;
$s2bs-dropdown-link-active-bg: $dropdown-link-active-bg !default;
$s2bs-dropdown-link-active-color: $dropdown-link-active-color !default;
$s2bs-dropdown-link-disabled-color: $dropdown-link-disabled-color !default;
$s2bs-dropdown-link-hover-bg: $dropdown-link-hover-bg !default;
$s2bs-dropdown-link-hover-color: $dropdown-link-hover-color !default;
$s2bs-font-size-base: $font-size-base !default;
$s2bs-font-size-lg: $font-size-lg !default;
$s2bs-font-size-sm: $font-size-sm !default;
$s2bs-input-color: $input-color !default;
$s2bs-input-bg: $input-bg !default;
$s2bs-input-border: $input-border-color !default;
$s2bs-input-border-radius: $input-border-radius !default;
$s2bs-input-btn-padding-x: $input-btn-padding-x !default;
$s2bs-input-btn-padding-y: $input-btn-padding-y !default;
$s2bs-input-btn-padding-x-lg: $input-btn-padding-x-lg !default;
$s2bs-input-btn-padding-y-lg: $input-btn-padding-y-lg !default;
$s2bs-input-btn-padding-x-sm: $input-btn-padding-x-sm !default;
$s2bs-input-btn-padding-y-sm: $input-btn-padding-y-sm !default;
$s2bs-input-disabled-bg: $input-disabled-bg !default;
$s2bs-input-focus-border-color: $input-focus-border-color !default;
$s2bs-input-focus-color: $input-focus-color !default;
$s2bs-input-height-inner: $input-height-inner !default;
$s2bs-input-height: $input-height !default;
$s2bs-input-height-inner-sm: $input-height-inner-sm !default;
$s2bs-input-height-sm: $input-height-sm !default;
$s2bs-input-height-inner-lg: $input-height-inner-lg !default;
$s2bs-input-height-lg: $input-height-lg !default;
$s2bs-input-placeholder-color: $input-placeholder-color !default;
$s2bs-line-height-base: $input-btn-line-height !default;
$s2bs-line-height-lg: $input-btn-line-height-lg !default;
$s2bs-line-height-sm: $input-btn-line-height-sm !default;

// Theme-specific variables
// -------------------------
$s2bs-caret-width: .3rem !default;
$s2bs-clear-selection-color: $s2bs-input-placeholder-color !default;
$s2bs-clear-selection-hover-color: $s2bs-btn-color !default;
$s2bs-dropdown-arrow-color: $s2bs-input-placeholder-color !default;
$s2bs-dropdown-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) !default;
$s2bs-dropdown-box-shadow-above: 0px -6px 12px rgba(0,0,0,.175) !default;
$s2bs-dropdown-header-font-size: $s2bs-font-size-sm !default;
$s2bs-dropdown-header-padding-vertical: $s2bs-input-btn-padding-y !default;
$s2bs-remove-choice-color: $s2bs-btn-color !default;
$s2bs-remove-choice-hover-color: $s2bs-btn-color !default;
$s2bs-screen-size-sm: map-get($container-max-widths, "sm") !default;
$s2bs-selection-choice-border-radius: $s2bs-border-radius !default;


// Bootstrap defaults not available as pre-defined variables
// These should not be overridden.
// -------------------------

// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L126
$s2bs-form-control-default-box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !default;

// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/mixins/_forms.scss#L60
$s2bs-form-control-focus-box-shadow: $s2bs-form-control-default-box-shadow, 0 0 8px rgba($s2bs-input-focus-color, 0.6) !default;

// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L127
$s2bs-form-control-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;


//
// Mixins
// --------------------------------------------------

// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L115
@mixin bootstrap-input-defaults {
  @include box-shadow($s2bs-form-control-default-box-shadow);
  background-color: $s2bs-input-bg;
  border: 1px solid $s2bs-input-border;
  color: $s2bs-input-color;
  font-size: $s2bs-font-size-base;

  @include border-radius($s2bs-input-border-radius);
}

// @see http://getbootstrap.com/css/#forms-control-validation
// @see https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_forms.scss#L388
@mixin validation-state-focus($color) {

  $shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten($color, 20%);

  .select2-dropdown,
  .select2-selection {
    border-color: $color;
  }

  .select2-container--focus .select2-selection,
  .select2-container--open .select2-selection {
    @include box-shadow($shadow);
    border-color: darken($color, 10%);
  }

  &.select2-drop-active {
    border-color: darken($color, 10%);

    &.select2-drop.select2-drop-above {
      border-top-color: darken($color, 10%);
    }
  }
}

// dropdown arrow when dropdown is open
@mixin dropdown-arrow {
  .select2-selection--single {
    /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */
    .select2-selection__arrow b {
      border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
      border-width: 0 $s2bs-caret-width $s2bs-caret-width $s2bs-caret-width;
    }
  }
}





.select2-container--bootstrap {
  display: block;
  /*------------------------------------*\
    #COMMON STYLES
\*------------------------------------*/
  .select2-selection {
    @include bootstrap-input-defaults;
    outline: 0;
    border: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

    &.form-control {
      @include border-radius($s2bs-input-border-radius);
    }
  }
  /**
  * Search field in the Select2 dropdown.
  */
  .select2-search--dropdown {
    .select2-search__field {
      @include bootstrap-input-defaults;
    }
  }
  /**
  * No outline for all search fields - in the dropdown
  * and inline in multi Select2s.
  */
  .select2-search__field {
    outline: 0;

    &::-webkit-input-placeholder {
      color: $s2bs-input-placeholder-color;
    }
    /* Firefox 18- */
    &:-moz-placeholder {
      color: $s2bs-input-placeholder-color;
    }
    /**
    * Firefox 19+
    *
    * @see http://stackoverflow.com/questions/24236240/color-for-styled-placeholder-text-is-muted-in-firefox
    */
    &::-moz-placeholder {
      color: $s2bs-input-placeholder-color;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $s2bs-input-placeholder-color;
    }
  }
  /**
  * Adjust Select2's choices hover and selected styles to match
  * Bootstrap 4's default dropdown styles.
  *
  * @see http://getbootstrap.com/components/#dropdowns
  */
  .select2-results__option {
    padding: $s2bs-input-btn-padding-y $s2bs-input-btn-padding-x;

    &[role=group] {
      padding: 0;
    }
    /**
    * Disabled results.
    *
    * @see https://select2.github.io/examples.html#disabled-results
    */
    &[aria-disabled=true] {
      color: $s2bs-dropdown-link-disabled-color;
    }
    /**
    * Hover state.
    */
    &[aria-selected=true] {
      background-color: $s2bs-dropdown-link-hover-bg;
      color: $s2bs-dropdown-link-hover-color;
    }
    /**
    * Selected state.
    */
    &--highlighted[aria-selected] {
      background-color: $s2bs-dropdown-link-active-bg;
      color: $s2bs-dropdown-link-active-color;
    }

    .select2-results__option {
      padding: $s2bs-input-btn-padding-y $s2bs-input-btn-padding-x $s2bs-input-btn-padding-y $s2bs-input-btn-padding-x * 3;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -$s2bs-input-btn-padding-x;
        padding-left: $s2bs-input-btn-padding-x*2;

        .select2-results__option {
          margin-left: -$s2bs-input-btn-padding-x*2;
          padding-left: $s2bs-input-btn-padding-x*3;

          .select2-results__option {
            margin-left: -$s2bs-input-btn-padding-x*3;
            padding-left: $s2bs-input-btn-padding-x*4;

            .select2-results__option {
              margin-left: -$s2bs-input-btn-padding-x*4;
              padding-left: $s2bs-input-btn-padding-x*5;

              .select2-results__option {
                margin-left: -$s2bs-input-btn-padding-x*5;
                padding-left: $s2bs-input-btn-padding-x*6;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__group {
    color: $s2bs-dropdown-header-color;
    display: block;
    padding: $s2bs-dropdown-header-padding-vertical $s2bs-input-btn-padding-x;
    font-size: $s2bs-dropdown-header-font-size;
    line-height: $s2bs-line-height-base;
    white-space: nowrap;
  }

  &.select2-container--focus,
  &.select2-container--open {
    .select2-selection {
      @include box-shadow($s2bs-form-control-focus-box-shadow);
      @include transition($s2bs-form-control-transition);
      border-color: $s2bs-input-focus-border-color;
    }
  }

  &.select2-container--open {
    /**
     * Make the dropdown arrow point up while the dropdown is visible.
     */
    .select2-selection .select2-selection__arrow b {
      border-color: transparent transparent $s2bs-dropdown-arrow-color transparent;
      border-width: 0 $s2bs-caret-width $s2bs-caret-width $s2bs-caret-width;
    }
    /**
     * Handle border radii of the container when the dropdown is showing.
     */
    &.select2-container--below {
      .select2-selection {
        @include border-bottom-radius(0);
        border-bottom-color: transparent;
      }
    }

    &.select2-container--above {
      .select2-selection {
        @include border-top-radius(0);
        border-top-color: transparent;
      }
    }
  }
  /**
  * Clear the selection.
  */
  .select2-selection__clear {
    color: $s2bs-clear-selection-color;
    //cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px;

    &:hover {
      color: $s2bs-clear-selection-hover-color;
    }
  }
  /**
  * Address disabled Select2 styles.
  *
  * @see https://select2.github.io/examples.html#disabled
  * @see http://getbootstrap.com/css/#forms-control-disabled
  */
  &.select2-container--disabled {
    .select2-selection {
      border-color: $s2bs-input-border;
      @include box-shadow(none);
    }

    .select2-selection,
    .select2-selection--multiple .select2-selection__choice {
      background-color: $s2bs-input-disabled-bg;
    }

    .select2-selection__clear,
    .select2-selection--multiple .select2-selection__choice__remove {
      display: none;
    }
  }
  /*------------------------------------*\
    #DROPDOWN
  \*------------------------------------*/
  /**
  * Dropdown border color and box-shadow.
  */
  .select2-dropdown {
    @include box-shadow($s2bs-dropdown-box-shadow);
    border: 0;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    overflow-x: hidden;
    margin-top: -1px;
    @include border-radius($s2bs-input-border-radius);

    &--above {
      @include box-shadow($s2bs-dropdown-box-shadow-above);
      margin-top: 1px;
    }
  }
  /**
  * Limit the dropdown height.
  */
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  /*------------------------------------*\
    #SINGLE SELECT2
  \*------------------------------------*/
  .select2-selection--single {
    height: $s2bs-input-height;
    line-height: $s2bs-line-height-base;
    padding: $s2bs-input-btn-padding-y ($s2bs-input-btn-padding-x + $s2bs-caret-width*3) $s2bs-input-btn-padding-y $s2bs-input-btn-padding-x;
    /**
    * Adjust the single Select2's dropdown arrow button appearance.
    */
    .select2-selection__arrow {
      position: absolute;
      bottom: 0;
      right: $s2bs-input-btn-padding-x;
      top: 0;
      width: $s2bs-caret-width;

      b {
        border-color: $s2bs-dropdown-arrow-color transparent transparent transparent;
        border-style: solid;
        border-width: $s2bs-caret-width $s2bs-caret-width 0 $s2bs-caret-width;
        height: 0;
        left: 0;
        margin-left: -$s2bs-caret-width;
        margin-top: -$s2bs-caret-width/2;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }

    .select2-selection__rendered {
      color: $s2bs-input-color;
      padding: 2px 0;
    }

    .select2-selection__placeholder {
      color: $s2bs-input-placeholder-color;
    }
  }
  /*------------------------------------*\
  #MULTIPLE SELECT2
  \*------------------------------------*/
  .select2-selection--multiple {
    min-height: $s2bs-input-height;
    padding: 0;
    height: auto;

    .select2-selection__rendered {
      box-sizing: border-box;
      display: block;
      line-height: $s2bs-line-height-base;
      list-style: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select2-selection__placeholder {
      color: $s2bs-input-placeholder-color;
      float: left;
      margin-top: 5px;
    }
    /**
     * Make Multi Select2's choices match Bootstrap 4's default button styles.
     */
    .select2-selection__choice {
      color: $s2bs-btn-color;
      background: $s2bs-btn-bg;
      border: 1px solid $s2bs-btn-border-color;
      //cursor: default;
      float: left;
      margin: -($s2bs-input-btn-padding-y - 1) 0 0 $s2bs-input-btn-padding-x/2;
      padding: 0 $s2bs-input-btn-padding-y;
      @include border-radius($s2bs-selection-choice-border-radius);
    }
    .select2-search--inline {
      .select2-search__field {
        background: transparent;
        padding: 0 $s2bs-input-btn-padding-x;
        height: $s2bs-input-height-inner;
        line-height: $s2bs-line-height-base;
        margin-top: 0;
        min-width: 5em;
      }
    }

    .select2-selection__choice__remove {
      color: $s2bs-remove-choice-color;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: $s2bs-input-btn-padding-y / 2;

      &:hover {
        color: $s2bs-remove-choice-hover-color;
      }
    }
    /**
     * Clear the selection.
     */
    .select2-selection__clear {
      margin-top: $s2bs-input-btn-padding-y;
    }
  }
  /**
  * Address Bootstrap control sizing classes
  *
  * 1. Reset Bootstrap defaults.
  * 2. Adjust the dropdown arrow button icon position.
  *
  * @see http://getbootstrap.com/css/#forms-control-sizes
  */
  /* 1 */
  .select2-selection--single.input-sm,
  .input-group-sm & .select2-selection--single,
  .form-group-sm & .select2-selection--single {
    @include border-radius($s2bs-border-radius-sm);
    font-size: $s2bs-font-size-sm;
    height: $s2bs-input-height-sm;
    line-height: $s2bs-line-height-sm;
    padding: $s2bs-input-btn-padding-y-sm $s2bs-input-btn-padding-x-sm + $s2bs-caret-width*3 $s2bs-input-btn-padding-y-sm $s2bs-input-btn-padding-x-sm;
    /* 2 */
    .select2-selection__arrow b {
      margin-left: -$s2bs-input-btn-padding-y-sm;
    }
  }

  .select2-selection--multiple.input-sm,
  .input-group-sm & .select2-selection--multiple,
  .form-group-sm & .select2-selection--multiple {
    min-height: $s2bs-input-height-sm;
    @include border-radius($s2bs-border-radius-sm);

    .select2-selection__choice {
      font-size: $s2bs-font-size-sm;
      line-height: $s2bs-line-height-sm;
      margin: ($s2bs-input-btn-padding-y-sm) 0 0 $s2bs-input-btn-padding-x-sm/2;
      padding: 0 $s2bs-input-btn-padding-y-sm;
    }

    .select2-search--inline .select2-search__field {
      padding: 0 $s2bs-input-btn-padding-x-sm;
      font-size: $s2bs-font-size-sm;
      height: $s2bs-input-height-inner-sm;
      line-height: $s2bs-line-height-sm;
    }

    .select2-selection__clear {
      margin-top: $s2bs-input-btn-padding-y-sm;
    }
  }

  .select2-selection--single.input-lg,
  .input-group-lg & .select2-selection--single,
  .form-group-lg & .select2-selection--single {
    @include border-radius($s2bs-border-radius-lg);
    font-size: $s2bs-font-size-lg;
    height: $s2bs-input-height-lg;
    line-height: $s2bs-line-height-lg;
    padding: $s2bs-input-btn-padding-y-lg $s2bs-input-btn-padding-x-lg + $s2bs-caret-width*3 $s2bs-input-btn-padding-y-lg $s2bs-input-btn-padding-x-lg;
    /* 1 */
    .select2-selection__arrow {
      width: $s2bs-caret-width;

      b {
        border-width: $s2bs-caret-width $s2bs-caret-width 0 $s2bs-caret-width;
        margin-left: -$s2bs-caret-width;
        margin-left: -$s2bs-input-btn-padding-y-lg;
        margin-top: -$s2bs-caret-width/2;
      }
    }
  }

  .select2-selection--multiple.input-lg,
  .input-group-lg & .select2-selection--multiple,
  .form-group-lg & .select2-selection--multiple {
    min-height: $s2bs-input-height-lg;
    @include border-radius($s2bs-border-radius-lg);

    .select2-selection__choice {
      font-size: $s2bs-font-size-lg;
      line-height: $s2bs-line-height-lg;
      @include border-radius($s2bs-selection-choice-border-radius);
      margin: ($s2bs-input-btn-padding-y-lg - 1) 0 0 $s2bs-input-btn-padding-x-lg/2;
      padding: 0 $s2bs-input-btn-padding-y-lg;
    }

    .select2-search--inline .select2-search__field {
      padding: 0 $s2bs-input-btn-padding-x-lg;
      font-size: $s2bs-font-size-lg;
      height: $s2bs-input-height-inner-lg;
      line-height: $s2bs-line-height-lg;
    }

    .select2-selection__clear {
      margin-top: $s2bs-input-btn-padding-y-lg;
    }
  }

  .select2-selection.input-lg.select2-container--open {
    @include dropdown-arrow;
  }

  .input-group-lg & .select2-selection {
    &.select2-container--open {
      @include dropdown-arrow;
    }
  }
  /*------------------------------------*\
  #RTL SUPPORT
  \*------------------------------------*/
  &[dir="rtl"] {
    /**
    * Single Select2
    *
    * 1. Makes sure that .select2-selection__placeholder is positioned
    *    correctly.
    */
    .select2-selection--single {
      padding-left: $s2bs-input-btn-padding-x + $s2bs-caret-width*3;
      padding-right: $s2bs-input-btn-padding-x;

      .select2-selection__rendered {
        padding-right: 0;
        padding-left: 0;
        text-align: right; /* 1 */
      }

      .select2-selection__clear {
        float: left;
      }

      .select2-selection__arrow {
        left: $s2bs-input-btn-padding-x;
        right: auto;

        b {
          margin-left: 0;
        }
      }
    }
    /**
     * Multiple Select2
     */
    .select2-selection--multiple {
      .select2-selection__choice,
      .select2-selection__placeholder,
      .select2-search--inline {
        float: right;
      }

      .select2-selection__choice {
        margin-left: 0;
        margin-right: $s2bs-input-btn-padding-x/2;
      }

      .select2-selection__choice__remove {
        margin-left: 2px;
        margin-right: auto;
      }
    }
  }
}





/*------------------------------------*\
  #ADDITIONAL GOODIES
\*------------------------------------*/


/**
 * Address Bootstrap's validation states
 *
 * If a Select2 widget parent has one of Bootstrap's validation state modifier
 * classes, adjust Select2's border colors and focus states accordingly.
 * You may apply said classes to the Select2 dropdown (body > .select2-container)
 * via JavaScript match Bootstraps' to make its styles match.
 *
 * @see http://getbootstrap.com/css/#forms-control-validation
 */

.has-warning {
  @include validation-state-focus(theme-color("warning"));
}

.has-danger {
  @include validation-state-focus(theme-color("danger"));
}

.has-success {
  @include validation-state-focus(theme-color("success"));
}

/**
 * Select2 widgets in Bootstrap Input Groups
 *
 * @see http://getbootstrap.com/components/#input-groups
 * @see https://github.com/twbs/bootstrap/blob/master/less/input-groups.less
 */

/**
 * Reset rounded corners
 */

.input-group > .select2-hidden-accessible {
  &:first-child + .select2-container--bootstrap > .selection > .select2-selection,
  &:first-child + .select2-container--bootstrap > .selection > .select2-selection.form-control {
    @include border-right-radius(0);
  }

  &:not(:first-child) + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection,
  &:not(:first-child) + .select2-container--bootstrap:not(:last-child) > .selection > .select2-selection.form-control {
    @include border-radius(0);
  }

  &:not(:first-child):not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection,
  &:not(:first-child):not(:last-child) + .select2-container--bootstrap:last-child > .selection > .select2-selection.form-control {
    @include border-left-radius(0);
  }
}

.input-group > .select2-container--bootstrap {
  display: table;
  table-layout: fixed;
  position: relative;
  z-index: 2;
  width: 100%;
  margin-bottom: 0;

  > .selection > .select2-selection.form-control {
    float: none;
  }
  /**
  * Adjust z-index like Bootstrap does to show the focus-box-shadow
  * above appended buttons in .input-group and .form-group.
  */
  &.select2-container--open, /* .form-group */
  &.select2-container--focus /* .input-group */ {
    z-index: 3;
  }
  /**
  * Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
  * Multi Select2's height which - depending on how many elements have been selected -
  * may grow taller than its initial size.
  *
  * @see http://getbootstrap.com/components/#input-groups
  */
  &,
  .input-group-btn,
  .input-group-btn .btn {
    vertical-align: top;
  }
}

/**
 * Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
 *
 * Provides `!important` for certain properties of the class applied to the
 * original `<select>` element to hide it.
 *
 * @see https://github.com/select2/select2/pull/3301
 * @see https://github.com/fk/select2/commit/31830c7b32cb3d8e1b12d5b434dee40a6e753ada
 */

.form-control.select2-hidden-accessible {
  position: absolute !important;
  width: 1px !important;
}

/**
 * Display override for inline forms
 */

.form-inline .select2-container--bootstrap {
  @media (min-width: $s2bs-screen-size-sm) {
    display: inline-block;
  }
}